.formulario-busca-suspensos {
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #42474a;
  }
}

.inputs-com-data {
  display: grid;
  grid-template-columns: 1fr 1fr;

  label {
    padding-bottom: 0.5em !important;
  }

  .input-data-sem-label {
    display: flex;
    align-items: center;
    margin: 0.5em 1em 0 0;
    justify-content: flex-end;
  }
}

.botoes-submit-pesquisa {
  display: flex;
  justify-content: flex-end;
}

.margin-da-pagina {
  margin-bottom: 15em;
}

.body-modal {
  margin: 1em 2em;

  .header-modal {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #42474a;
    margin: 0 0 0.5em 0;
  }

  .section-produtos-itens {
    border: 1px solid #e9eaea;
    box-sizing: border-box;
    border-radius: 5px 5px 0px 0px;
    .item-produto-modal {
    }
    .item-header-produto-modal {
      hr {
        padding: 0 1em !important;
        margin-top: 0.5em !important;
        margin-bottom: 1em !important;
        width: 96% !important;
      }
      .item-grid-produto {
        display: grid;
        grid-template-columns: 2fr 2fr 1.5fr 1fr 1fr;
        height: 48px;

        div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding-left: 1em;
          border-left: 1px solid #c2c2c2;

          &:first-child {
            border-left: 0px;
          }
        }
      }

      .item-prod-detalhe {
        background: #e9eaea;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }

      .item-grid-detalhe-produto {
        display: grid;
        grid-template-columns: 2fr 2fr 3.5fr;

        div {
          display: flex;
          flex-wrap: wrap;
          padding-left: 1em;
          margin-top: 1.5em;

          label {
            width: 100%;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #686868;
          }
          p {
            width: 100%;
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            color: #353535;
            margin-top: 0.5em;
          }
        }
      }

      .item-grid-produto:first-child {
        background: #f5f6f8;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
      }
      .item-grid-produto:last-child {
        background: #e9eaea;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.input-data-sem-label {
  display: flex;
  align-items: center;
  // margin: 0.5em 1em 0 0;
  // justify-content: flex-end;
}

.justificativa-value-table p {
  font-weight: bolder;
}
