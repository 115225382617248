.reclamacao-title {
  color: #198459;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.item-horizontal {
  display: flex;
}
.label-item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #686868;
  margin-bottom: 0.5em;
}

.value-item {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #42474a;
}

.value-uppercase {
  text-transform: uppercase;
  font-weight: bold;
}

.value-item p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #42474a;
}

.value-uppercase p {
  text-transform: uppercase;
  font-weight: bold;
}

.resultados-busca-produtos hr {
  width: 100%;
}

.right {
  float: right;
}
