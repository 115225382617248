@import "../../../../styles/variables.scss";

.solicitacao-similar-info {
  background-color: #f9f9f9;
}

.remove-padding {
  padding: 0px;
}

.row {
  padding: 0px !important;
  margin: 0px !important;
}
