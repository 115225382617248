.formulario-tipo-alimentacao {
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;

  .header {
    font-weight: bold;
    color: #42474a;
  }

  .tipos-de-unidade {
    display: flex;
    flex-direction: column;
    padding: 1em 0 2em 0;

    header {
      color: #42474a;
      padding-bottom: 0.3em;
    }

    article {
      width: 30%;
    }
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #198459;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #198459;
}

.title-tipos-alimentacao {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #686868;
}

.title-periodo {
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #353535;
  margin-top: 10px;
}

.icon-arrow {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #353535;
  margin-top: 10px;
}

.custom-card-header {
  border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
  margin-bottom: 0;
  border-bottom: 1px solid #e3e6f0;
  background-color: #f8f5f5 !important;
}
