@import "../../../styles/variables.scss";

.solicitacao-consolidada-collapse {
  padding-left: 15px !important;
}

.gatilho-style {
  color: #0c6b45 !important;
  padding-left: 10px !important;
  margin-bottom: 1px !important;
}

.icon-padding {
  padding-left: 10px;
}

.value-black p {
  font-weight: 600;
}

.solicitacao-similar-info {
  background-color: #f9f9f9;
}

.table-solicitacoes-similares {
  width: 100% !important;
}

.thead-solicitacoes-similares {
  width: 100% !important;
}

.tr-solicitacoes-similares {
  background-color: black;
  border: none;
  height: 50px;
}

.thead-td-solicitacoes-similares {
  color: white !important;
  padding: 10px !important;
  border-left: none !important;
}

.tbody-td-solicitacoes-similares {
  color: black !important;
  padding: 10px !important;
  border-left: none !important;
}

.remove-padding {
  padding: 0px;
}

table.faixas-etarias-cei {
  width: 98%;

  thead {
    tr {
      background-color: $grayBackground;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid $grayDisabled;
      color: $blackInput;

      th {
        padding: 1em;
        &:not(:first-child) {
          border-left: 1px solid $grayDisabled;
        }
      }
    }
  }

  tbody {
    tr {
      border: 1px solid $grayDisabled;
      border-top: none;
      color: $blackSubtle;

      td {
        padding: 0.5em;
        &:not(:first-child) {
          border-left: 1px solid $grayDisabled;
        }
      }
    }
  }
}

.label-cemei {
  background: #e6f6ea;
  border: 1px solid #83d0ae;
  border-radius: 5px;
  margin: 1% 0px;
  width: 100%;
  padding: 8px 15px;
  height: 40px;
}

.label-cemei-info {
  color: #0c6b45;
  font-weight: 600;
}

.text-black-tabela-cemei {
  color: black !important;
}

.bg-black {
  background-color: #333333 !important;
}

.fontWeight600 {
  font-weight: 600 !important;
}
