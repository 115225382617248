@import "../../../styles/variables.scss";

.card-shortcut {
  margin-top: 1rem;
  min-height: 245px;
  .card-header {
    font-size: 16px;
    padding: 10px;
    color: $blackSubtle;
  }
  .card-body {
    padding: 20px 10px !important;
    text-align: justify;
    .card-text {
      font-size: 10px;
      color: $gray;
    }
  }
  .card-footer {
    text-align: center;
    background-color: $white;
    border-top: none;
    .card-link {
      font-size: 14px;
      font-weight: bold;
      color: $blue;
    }
  }
}
