div.graficos-relatorio-ga {
  .total-por-status {
    canvas {
      height: 450px !important;
      width: 600px !important;
    }
  }
}

@media only screen and (max-width: 1367px) {
  div.graficos-relatorio-ga {
    .total-por-status {
      canvas {
        height: 300px !important;
        width: 400px !important;
      }
    }
  }
}
