.grid-box {
  display: grid;
  padding: 1em !important;

  header {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #42474a;
  }

  .wizard {
    margin-top: 1.5em;
  }

  .footer-botoes {
    display: flex;
    justify-content: flex-end;

    .desativado {
      border: 1px solid #dadada;
      color: #dadada;
      cursor: default;
      &:hover {
        background-color: transparent;
        color: #dadada;
      }
    }

    margin-bottom: 10em;
  }

  .conteudo-wizard {
    display: grid;
    grid-template-columns: 30% 0.1% 68%;
    column-gap: 0.9%;
    margin-top: 2em;

    .borda {
      border-right: 1px solid #ced4da;
    }

    .numero-alunos {
      .form-qtd-alunos {
        header {
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          /* identical to box height */

          color: #42474a;
        }

        .grid-form-alunos {
          display: grid;
          grid-template-columns: 100%;
          column-gap: 4%;

          .quantidade-alunos-box {
            background: #ffffff;
            border: 1px solid #ced4da;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 0.5em;
            height: 38px;
          }

          div {
            i {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #086397;
              width: 38px;
              height: 38px;
              border: 1px solid #086397;
              box-sizing: border-box;
              border-radius: 3px;
              cursor: pointer;

              &:hover {
                color: #ffffff;
                background-color: #086397;
              }
            }
          }
        }
      }
    }

    .horarios-alimentacao {
      margin-left: 10px;
      display: grid;
      grid-template-columns: 38% 29% 29%;
      column-gap: 2%;

      nav {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height */

        color: #42474a;
      }

      .combo-tipo-alimentacao {
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 4px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
      }

      .combo-atual {
        background: #ffffff;
        border-color: #82b7e8;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #42474a;
      }

      .proximo-combo {
        background: #f5f6f8;
        border-color: #dadada;

        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        nav {
          color: #dadada !important;
        }
      }

      .combo-ativo {
        .ant-time-picker-input {
          border-color: #82b7e8;

          &::placeholder {
            color: #42474a !important;
          }
        }
      }
    }
  }
}

.modal-form-att-alunos {
  .input-qtd-alunos {
    margin-bottom: 1em;

    .input {
      width: 180px;
    }
  }

  .aviso-modal {
    border: 1px solid #b40c02;
    border-radius: 4px;
    padding: 1em;

    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #b40c02;
  }

  .justificatica-quantidade-alunos {
    margin-bottom: 4em;
  }
}

.combo-passado {
  border: 1px solid #dadada !important;
}

.checado-item-ok {
  height: 50%;
  padding: 0px 3px;
  border: 1px solid #52c41a;
  border-radius: 50%;
  background: #52c41a;
  display: flex;
  align-items: center;
  i {
    font-size: 10px;
    color: #ffffff;
  }
}

.horario-confirmado {
  .ant-time-picker-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #ffffff !important;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-time-picker-icon {
    // background-color: #52c41a;
    i {
      color: #52c41a;
    }
  }

  .input-horario .ant-time-picker .ant-time-picker-input {
    color: black;
  }
}

.grid-relatorio {
  padding: 1em;
  margin-bottom: 5em;
  header {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #42474a;
    margin: 1em;
  }

  article {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 1em;

    .tipo-periodos {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      border-bottom: 1px solid #a8a8a8;
      letter-spacing: 0.01em;
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      color: #686868;
    }

    .teste {
      height: 45px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ebeff2;

      .periodo-escolar {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #323c47;
      }

      .icons-action {
        width: 100px;
        display: grid;
        grid-template-columns: 50% 50%;

        .fas {
          line-height: 18px;
          color: #a4a4a4;
          font-weight: 900;

          cursor: pointer;
        }

        .fa-angle-up,
        .fa-angle-down {
          font-size: 17px;
        }

        .fa-pen {
          font-size: 14px;
        }
      }

      &.ativo {
        background-color: #f2fbfe;
      }
    }

    main {
      &.ativo-conteudo {
        background-color: #f2fbfe;
      }

      &.grid-conteudo-periodo {
        padding: 10px;
        display: grid;
        grid-template-columns: 40% 20% 40%;
        margin-bottom: 0.5em;

        nav {
          font-weight: normal;
          font-size: 14px;
          line-height: 188.62%;
          margin-bottom: 0.5em;

          color: #353535;
        }
        div {
          font-weight: bold;
          font-size: 14px;
          line-height: 188.62%;
          margin-bottom: 0.5em;

          color: #353535;
        }
      }
    }
  }
}
