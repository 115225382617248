.card-laboratorios {
  min-height: 600px;
  margin-bottom: 18em !important;

  .laboratorios {
    hr {
      margin: 2rem 0;
    }
  }
}
