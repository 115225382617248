.card-cronograma-entrega {
  min-height: 600px;
  margin-bottom: 18em !important;

  .cronograma-entrega {
    .menu-inferior {
      font-size: 14px !important;
    }

    hr {
      margin: 2rem 0;
    }
  }
}
