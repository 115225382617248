.tabela-relatorio-quant-diag-dieta-esp {
  .grid-somente-ativas {
    grid-template-columns: 50% 50%;
  }

  .grid-nutri-todos {
    grid-template-columns: 20% 15% 18% 10% 15% 10% 12%;
  }

  .grid-nutri-todos-sem-escola {
    grid-template-columns: 35% 25% 10% 12% 9% 9%;
  }

  .grid-nutri-unico {
    grid-template-columns: 30% 27.5% 17.5% 10% 15%;
  }

  .grid-nutri-unico-sem-escola {
    grid-template-columns: 55% 15% 15% 15%;
  }

  .grid-dre-todos {
    grid-template-columns: 25% 20% 15% 15% 15% 10%;
  }

  .grid-dre-todos-sem-escola {
    grid-template-columns: 50% 14% 12% 12% 12%;
  }

  .grid-dre-unico {
    grid-template-columns: 40% 30% 15% 15%;
  }

  .grid-dre-unico-sem-escola {
    grid-template-columns: 70% 15% 15%;
  }

  .grid-escola-todos {
    grid-template-columns: 20% 15% 18% 10% 15% 10% 12%;
  }

  .grid-escola-unico {
    grid-template-columns: 36% 30% 16% 10% 8%;
  }

  .row-quantitativo-card {
    border: 1px solid #dfdfdf;
    padding: 10px 15px 30px 15px;
  }

  .header-quantitativo-por-terceirizada {
    display: grid;
    background: #f5f6f8;
    min-height: 52px;

    div {
      padding: 5px 10px;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #42474a;
      border: 1px solid #e9eaea;
    }
  }

  .row-quantitativo-nome {
    display: grid;
    min-height: 48px;
    background-color: #f9f9f9;

    div {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding: 5px 10px;

      display: flex;
      align-items: center;

      color: #686868;

      border: 1px solid #e9eaea;
    }
  }

  .row-quantitativo-status {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    height: 68px;

    .status-flex-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      div {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        padding: 10px;
        display: flex;
        align-items: center;
        color: #686868;

        &:first-child {
          font-weight: bold;
          color: #42474a;
        }
      }
    }

    > div {
      border-bottom: 1px solid #e9eaea;
    }
  }
}
