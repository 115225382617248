@import "styles/variables.scss";

.header-alteracao-cei {
  display: flex;
  section {
    display: block;
  }
  .section-form-motivo {
    flex-grow: 100;
  }
  .section-form-datas {
    flex-grow: 2;
    margin-left: 1em;
    label.col-form-label {
      font-size: 14px;
    }
  }
  .react-datepicker-popper {
    left: -35px !important;
  }
}
.tabela-substituicao th {
  table-layout: fixed;
  width: 33.33%;
}
.tabela-substituicao.tabela-substituicao-parcial {
  th:first-child {
    width: 66.66%;
  }
}

.select-tipo-alimentacao-de {
  margin-top: 7px;
}

.form-alteracao-cei {
  .period-quantity {
    border-radius: 7px;
    padding: 0.5em;
    color: $blackInput;

    &.number-0 {
      background-color: #fff7cb;
      border: 1px solid $yellow;
    }
    &.number-1 {
      background-color: #ebedff;
      border: 1px solid $blue;
    }
    &.number-2 {
      background-color: #ffeed6;
      border: 1px solid $orange;
    }
    &.number-3 {
      background-color: #eaffe3;
    }
    &.number-4 {
      background-color: #e4f1ff;
    }
    &.number-5 {
      background-color: #d6ffea;
    }
  }

  div.tabela-header-periodos {
    font-size: 14px;
    color: $gray;
  }

  table.faixas-etarias-cei {
    width: 98%;
    font-size: 14px;

    thead {
      tr {
        background-color: $grayBackground;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border: 1px solid $grayDisabled;
        color: $blackInput;

        th {
          padding: 1em;
          &:not(:first-child) {
            border-left: 1px solid $grayDisabled;
          }
        }
      }
    }

    tbody {
      tr {
        border: 1px solid $grayDisabled;
        border-top: none;
        color: $blackSubtle;

        td {
          padding: 0.5em;
          &:not(:first-child) {
            border-left: 1px solid $grayDisabled;
          }
        }
      }
    }
  }
}
.multi-select .dropdown .dropdown-heading {
  display: flex !important;
  justify-content: space-between !important;
}
