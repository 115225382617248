@import "styles/variables.scss";

.listagem-questoes-por-produtos {
  .titulo-verde {
    color: $greenDark;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 6px;
    font-weight: bold;
  }

  .bold-verde {
    color: $green;
    font-weight: bold;
  }

  article {
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e9eaea;
    box-sizing: border-box;
    margin: 10px 0 15px 0;

    .grid-table {
      height: 40px;
      font-size: 14px;
      line-height: 16px;
      display: grid;
      align-items: center;
      text-align: center;
      grid-template-columns: 3fr 10fr 5fr 2fr;
    }

    .header-table {
      background: #f5f6f8;
      color: #42474a;
      font-weight: bold;

      div {
        display: grid;
        align-items: center;
        text-align: left;
        height: 100%;
        border-right: 1px solid #e9eaea;
        padding: 0;
        padding-left: 8px;

        &:last-child {
          border-right: 0px solid #e9eaea;
        }
      }
    }

    .body-table {
      font-weight: 500;
      color: #686868;

      i.verde {
        cursor: pointer;
        color: $green;
      }

      .coluna-acoes {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
      }

      .link-acoes {
        cursor: pointer;
        text-decoration: underline;
        padding-right: 4px;

        a {
          color: #686868;
        }

        .fas {
          text-decoration: none;
          font-size: 16px;
          padding-right: 4px;
          padding-left: 4px;
        }

        button {
          background: none;
          outline: none;
          border: none;
          cursor: pointer;
        }
      }

      div {
        display: grid;
        align-items: center;
        text-align: left;
        height: 100%;
        border-right: 1px solid #e9eaea;
        padding: 0 0 0 8px;

        &:last-child {
          border-right: 0px solid #e9eaea;
        }
      }

      .botao-expandir-questoes {
        color: $green;
        cursor: pointer;
        text-decoration: underline;
        font-weight: bold;
      }
    }

    .show {
      border-top: 1px solid #e9eaea;
    }

    .container-questoes {
      .questoes {
        border: 1px solid $grayInputBorder;
        border-radius: 5px;
        height: 300px;
        overflow: auto;
        font-size: 14px;
        padding: 7.5px;
      }
    }
  }
}
