@import "../../../../styles/variables.scss";

.carregando-conteudo {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.descricao-reclamação {
  margin-bottom: 1em;

  .motivo-data-reclamacao {
    display: flex;
    justify-content: space-between;
    padding: 0 1em;

    div {
      color: $blackInput;
      font-weight: bold;
    }
  }

  .box-detalhe-reclamacao {
    border: 1px solid $blackInput;
    border-radius: 4px;
    min-height: 60px;
    display: flex;
    align-items: center;
    padding: 0 1em;

    p {
      margin: 0;
      padding: 0;
      color: $gray;
    }
  }
}
