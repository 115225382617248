@import "../../../../../../../../styles/variables.scss";

.filtros-relatorios-visita {
  input {
    height: 40px !important;
  }

  .dropdown {
    height: 40px;
    border-radius: 0.35rem !important;

    .dropdown-heading {
      height: 100% !important;
      border: 1px solid $grayInputBorder;

      .dropdown-heading-dropdown-arrow {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
