.input-make-field {
  .ant-select-selector .ant-select-selection--single {
    height: 38px;
  }
  .ant-select-selector .ant-select-selection__rendered {
    line-height: 38px;
  }
}

.background-loading {
  margin: 250px 0 250px 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  margin-top: 0.4em;
  height: 37px;
  padding: 0 11px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  cursor: auto;
  height: 37px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 37px;
}

.ant-select.ant-select-outlined.ant-select-in-form-item.input-make-field.ant-select-single {
  height: 100%;
}
