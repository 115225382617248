@import "../../../styles/variables.scss";

.tabs {
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  margin-left: 1em;
  margin-right: 1em;
  .tab {
    padding-bottom: 5px;
    &.active {
      color: $green;
      border-bottom: 3px solid $green;
    }
    &.inactive {
      color: $grayPlaceholder;
      border-bottom: 3px solid transparent;
    }
  }
}
