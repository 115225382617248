@import "../../../styles/variables.scss";

.profile {
  .card {
    .card-body {
      margin: 5em 3em;
      .information {
        border-right: 1px solid $grayDisabled;
        .avatar {
          svg {
            border: 2px solid $green;
            border-radius: 50%;
            height: 150px;
            width: 150px;
          }
        }
        .name {
          padding-top: 1em;
          color: $blackInput;
          font-size: 18px;
          font-weight: bold;
        }
        .data {
          padding-top: 1em;
          color: $blackInput;
          font-size: 14px;
        }
      }
      .form {
        margin-top: 2em;
        margin-left: 1em;
        .password {
          cursor: pointer;
          padding-top: 2em;
          padding-left: 2em;
          font-size: 14px;
          color: $blue;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.modal-50w {
  .custom-margin {
    margin-top: 8em;
  }
}
