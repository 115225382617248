@import "styles/variables.scss";

.card-logo {
  height: 218px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 6px solid #e3e6f0 !important;
  transition: 0.5s;

  .card-title {
    padding-top: 25px;
    font-weight: bold;
    text-align: center;
  }

  &.disabled {
    opacity: 0.5;
    color: $green;
    cursor: initial;
  }

  &:not(.disabled):hover {
    color: #2c98f0;
    background-color: $green;
    border-bottom-color: $white !important;
    transition: 0.5s;

    svg {
      path {
        stroke: $white;
      }
    }
    svg.fill-white {
      path {
        fill: $white;
      }
    }

    .card-title {
      color: $white !important;
    }
  }

  .icon-component {
    text-align: center;
  }
}
