.card-cadastro-ficha-recebimento {
  .label-peso-embalagem {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    color: #42474a;
    align-items: flex-end;
    padding-bottom: 7.5px;
  }

  .radio-sistema-vedacao {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  #observacoes {
    textarea {
      min-height: 125px;
    }
  }
}
