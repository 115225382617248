@import "styles/variables.scss";

.filtros-controle-de-frequencia {
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
    margin-top: 0.2rem;
  }

  .ant-form-item {
    flex: 1;
    width: 100%;

    & > div {
      display: flex;
      flex-direction: column;

      .ant-form-item-label {
        display: flex;

        label {
          justify-self: flex-start;
        }
      }
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    margin-top: 0;
  }

  .datepicker-data_final {
    align-self: center;

    .datepicker {
      margin-top: 0.8rem;
    }
  }
}
