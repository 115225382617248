@import "../../../styles/variables.scss";

.password-requirements {
  margin-top: auto;
  font-size: 10px;
  .title {
    color: $grayPlaceholder;
    font-weight: bold;
  }
  .requirements {
    .accepted {
      padding: 5px 0;
      color: $green;
    }
    .denied {
      padding: 3px 0;
      color: $red;
    }
    i {
      padding-left: 10px;
    }
  }
}
