@import "styles/variables.scss";

.sub-titulo {
  font-weight: bold;
  color: #353535;
}

.card.relatorio-dietas-especiais {
  padding-bottom: 16px;
  min-height: 52vh;
}

.quantitativo-dietas-autorizadas {
  padding-left: 0 !important;
  p {
    color: $green;
    font-size: 16px;
    font-weight: 600;
  }
}

.totalizador-dietas-autorizadas {
  padding: 0.5em;
  color: $green;
  font-weight: 500;
  border: 1px solid $green;
  border-left: 5px solid $green;
  border-radius: 4px;

  .valor {
    font-weight: bolder;
  }
}
