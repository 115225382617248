.titulo-grid-alunos-matriculados {
  color: #0c6b45;
  font-weight: 600;
  font-size: 14px;
}

.helper-grid-alunos-matriculados {
  color: #333333;
  font-weight: 500;
  font-size: 14px;
}

.sem-resultado-alunos-matriculados {
  text-align: center;
  font-size: 16px;
  margin-top: 12px;
}
