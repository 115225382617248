.card-unidades-medida {
  min-height: 600px;
  margin-bottom: 18em !important;

  .unidades-medida {
    hr {
      margin: 2rem 0;
    }
  }
}
