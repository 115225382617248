@import "../../../styles/variables.scss";

div.input {
  font-size: 14px;
  &.icon {
    display: inline-flex;
    input {
      width: 300px;
    }
    i {
      color: $blue;
      z-index: 1;
      margin-left: -30px;
      margin-top: 10px;
    }
  }
  &.show-hide-password {
    position: relative;
    input {
      width: 100%;
    }
    i.show-hide {
      color: $blackInput;
      position: absolute;
      right: 15px;
      top: 45px;
      cursor: pointer;
    }
  }
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
  }
  label {
    font-weight: 500;
    color: $gray;
  }
  input {
    font-size: 14px;
    color: $blackInput;
    font-weight: normal;
    &:focus {
      color: $blackInput;
    }
    &::placeholder {
      color: $grayPlaceholder;
      font-weight: normal;
    }
    &.invalid-field:not(:focus):invalid {
      box-shadow: none;
      border-color: $red;
    }
    &:disabled {
      background-color: $grayDisabled;
    }
  }
  .input-com-prefixo {
    display: flex;
    align-items: center;
    border: 1px solid $grayInputBorder;
    border-radius: 4px;
    padding-left: 0.5rem;
    overflow: hidden;
  }

  .input-com-prefixo .prefix {
    color: #999;
  }

  .input-com-prefixo input {
    flex-grow: 1;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0.5rem;
  }

  .input-com-prefixo:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(23, 100, 210, 0.23);
  }
}
