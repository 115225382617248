.card-tipos-embalagens {
  min-height: 600px;
  margin-bottom: 18em !important;

  .tipos-embalagens {
    hr {
      margin: 2rem 0;
    }
  }
}
