.relatorio-quantitativo-solic-dieta-esp {
  min-height: 64vh;
  .row-botao-imprimir {
    justify-content: flex-end;
    padding: 0.8em;
  }
  .mensagem-sem-registros {
    text-align: center;
  }
}
