@import "../../../styles/variables.scss";

.linked-card {
  a {
    color: $green;
    &:hover {
      text-decoration: none;
    }
  }
}

.cadastro,
.modal-cadastro-lote {
  .col-sm-12,
  .col-xs-12,
  .col-md-12,
  .col-lg-12 {
    padding: 0;
  }
  .card {
    .card-body {
      .card-title {
        color: $blackInput;
      }
    }
  }
  ul.select-list {
    li {
      background-color: $blue;
    }
  }
  label {
    &.label {
      font-weight: 600;
      font-size: 14px;
      color: $gray;
      span {
        color: $red;
      }
    }
    &.link {
      cursor: pointer;
      font-weight: bold;
      color: $blue;
    }
    &.label-selected-unities {
      font-weight: normal;
      font-size: 12px;
    }
  }
  .value-selected-unities {
    padding-top: 10px;
    font-weight: bold;
    font-size: 12px;
    color: #353535;
  }
}

.modal-cadastro-lote {
  p {
    font-size: 14px;
    color: $gray;
    margin-bottom: 0;
    &:first-child {
      font-weight: bold;
    }
  }
}

.card-body {
  &.card-table-cadastro {
    padding: 0 !important;
  }

  &.contratos-relacionados {
    .excluir-form {
      display: flex;
      justify-content: flex-end;
    }

    .excluir {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 12px;
    }
  }
}

table.cadastro {
  width: 100%;
  .input-search {
    width: 50%;
    padding-right: 10px;
    border: none;
    border-bottom: 1px solid $gray;
    &::placeholder {
      text-align: right;
    }
  }

  th {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $gray;
    border-bottom: 2px solid #a8a8a8;
    padding-bottom: 10px;
    &:first-child {
      padding-left: 20px;
    }
  }
  td {
    font-size: 14px;
    color: #707683;
    padding: 15px 0;
    i {
      position: absolute;
      padding-left: 10%;
      color: #c8c8c8;
    }
    &:first-child {
      padding-left: 20px;
      width: 50%;
      color: $blackInput;
      font-weight: bold;
    }
    &:nth-child(2) {
      width: 19%;
    }
  }
  tr.relationed-company-title {
    background: #f2fbfe;
    td {
      font-weight: 600;
      font-size: 14px;
      color: $gray;
      padding: 10px 0 0 0;
      border: none;
      &:first-child {
        padding-left: 20px;
      }
    }
  }
  tr.relationed-companies {
    background: #f2fbfe;
    td {
      background: #f2fbfe;
      font-weight: bold;
      font-size: 14px;
      color: $blackInput;
      border: none;
      padding: 10px 0;
      &.link {
        color: $blue;
      }
      span {
        font-weight: 600;
        color: #707683;
      }
      &:first-child {
        padding-left: 20px;
      }
    }
  }
}

.button-action {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.lotes_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 15px 10px 15px;

  .drop-down-lotes {
    width: 50%;
    padding-right: 15px;
  }
  .select-lotes {
    width: 50%;
    padding-left: 15px;
  }
}

table.cadastro-empresa {
  width: 100%;
  .input-search {
    width: 50%;
    padding-right: 10px;
    border: none;
    &::placeholder {
      padding-right: 10px;
      text-align: right;
    }
  }

  th {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $gray;
    border-bottom: 2px solid #a8a8a8;
    padding-bottom: 10px;
    &:first-child {
      padding-left: 20px;
    }
    width: auto;
    &:last-child {
      max-width: 150px;

      input {
        width: 80%;
      }
    }
  }

  td {
    font-size: 14px;
    color: #707683;
    padding: 15px 0;
    border-bottom: 1px solid #ebeff2;
    height: 75px !important;
    &:first-child {
      padding-left: 20px;
      font-weight: bold;
    }
    &:nth-child(2) {
      padding-left: 15px;
    }
    &:nth-child(5) {
      text-align: center;
    }
    &:nth-child(6) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .nome-empresa {
    width: auto;
    font-weight: bold;
  }

  .descricao {
    font-weight: bold;
    padding-right: 10px;
    margin: 0;
  }
  .valor-desc {
    font-weight: bold;
    color: #353535;
  }

  tr.relationed-company-title {
    background: #f2fbfe;
    td {
      font-weight: 600;
      font-size: 14px;
      color: $gray;
      padding: 10px 0 0 0;
      border: none;
      &:first-child {
        padding-left: 20px;
      }
    }
  }
  .detalhe-empresa {
    background-color: #fff;
    font-weight: bold;

    td {
      border: 0px;
      color: #000;
    }
  }
  tr {
    td.container-detalhe {
      background: #fff;
      padding: 0px 20px 15px 20px;

      .secao-empresa {
        display: grid;

        .endereco-empresa {
          display: grid;
          grid-template-columns: 50% 50%;
          margin-bottom: 20px;
        }
        .tipo-de-empresa {
          display: grid;
          grid-template-columns: 33% 33% 33%;
          margin-bottom: 20px;
        }
        .contato-empresa {
          margin-top: 1%;
          display: grid;
          grid-template-columns: 33% 33% 33%;
        }
        .contrato-empresa {
          margin-top: 1%;
          display: grid;
          grid-template-columns: 50% 50%;
        }
      }

      .secao-representante {
        display: grid;

        .nome-representante {
          display: grid;
          grid-template-columns: 60% 40%;
        }
        .contato-representante {
          margin-top: 2%;
          display: grid;
          grid-template-columns: 40% 60%;
        }

        .contato-nutri {
          margin-top: 2%;
          display: grid;
          grid-template-columns: 60% 40%;
        }
      }
    }
    .linha-detalhe {
      margin: 1.5em 2em 1em 0;
      border: 1px #a8a8a8 solid;
    }
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 10%;
}

.button-action {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.lotes_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 15px 10px 15px;

  .drop-down-lotes {
    width: 50%;
    padding-right: 15px;
  }
  .select-lotes {
    width: 50%;
    padding-left: 15px;
  }
}

.container-fields {
  display: grid;
  grid-template-columns: 95% 5%;

  .fields-set {
    display: grid;
    grid-template-columns: 38% 58%;
    grid-template-rows: 50% 50%;
    grid-column-gap: 4%;
  }

  .fields-set-contatos {
    display: grid;
    grid-template-columns: 24% 24% 40% 2%;
    grid-template-rows: 50% 50%;
    grid-column-gap: 3%;

    .deletar-contato {
      margin-top: 30px;
      height: 35px;
    }
  }

  .adicionar-contato {
    height: 35px;
  }

  .fields-set-edital {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 52% 44%;
    grid-column-gap: 4%;
  }

  .fields-set-edital-plus {
    display: flex;
    justify-content: flex-end;

    div {
      width: 44%;
    }
  }

  .button-field {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.linha-form {
  margin: 0 !important;
}

.cadastro {
  .dropdown-content {
    position: relative !important;
    label[aria-selected="true"] {
      font-weight: bold !important;
      font-size: 14px;
      color: white !important;
      background-color: #109cf1 !important;
    }
  }
}

.lista-lotes {
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    font-weight: bold;
    cursor: pointer;
    color: $blue;
  }
}

.pesquisar {
  display: flex;
  justify-content: flex-end;
  margin: 0 2em;

  i {
    margin: 0.5em;
  }
}

.btn-action {
  padding-right: 2em !important;
}

section {
  display: flex;

  .header-form {
    padding: 1.5em 32px 0 32px;

    nav {
      color: $gray;
    }

    .header-button {
      margin-top: 0.5em;
      width: 242px;
      height: 40px;
      border-radius: 4px;
      border: solid 1px $blue;
    }
  }

  .dados-editais {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 1em;
  }

  .input-duplo {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-rows: 15px 60px;
    grid-column-gap: 4%;
  }

  .input-unico {
    display: grid;
  }

  .titulo {
    padding: 0 32px;
  }
}
.data-processo-adm {
  display: grid;

  .inputs-processo {
    display: grid;
    grid-template-columns: 74% 25%;
    grid-column-gap: 2%;
  }
}

.inputs-select-lote-dre {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;
}

.container-lote-dre {
  margin: 1.5em 0;
}

.section-contrato-vigencia {
  display: grid;
  grid-template-columns: 41% 50% 3%;
  grid-column-gap: 2%;
  margin-bottom: 1em;

  section {
    .colunas {
      display: grid;
      grid-template-columns: 48% 48%;
      grid-column-gap: 2%;
      margin-top: 1em;

      .coluna {
        display: grid;
        grid-template-columns: 100%;
      }
    }
  }

  aside {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.contrato {
  margin-top: 1em;
}

* {
  font-family: Roboto;
}

.modal-cadastro-edital {
  margin: 0 1em;
  display: grid;

  header {
    color: $gray;
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: bold;
  }

  .detalhes {
    font-weight: bold;
    color: #353535;
    font-size: 14px;

    span {
      font-family: Roboto !important;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.29;
      color: #353535;
    }

    .vigencias {
      display: flex;
    }

    .iteracao-elementos {
      display: flex;

      .elementos {
        margin-left: 0.5em;
      }
    }
  }
}

table.cadastro-lote {
  width: 100%;
  th {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $gray;
    border-bottom: 2px solid #a8a8a8;
    padding-bottom: 10px;
    &:first-child {
      padding-left: 20px;
      width: 25%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 25%;
    }
  }
  td {
    font-size: 14px;
    border-bottom: 1px #e8e8e8 solid;
    height: 60px;

    &:first-child {
      padding-left: 20px;
      width: 30%;
      font-weight: bold;
      color: #323c47;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 35%;
    }
    padding: 0.5em;
  }
  .btn-acao {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .menu-table {
    display: grid;
    grid-template-columns: 67% 33%;
  }

  tr.relationed-companies {
    background: #f2fbfe;
    td {
      background: #f2fbfe;
      font-weight: bold;
      font-size: 14px;
      color: #353535;
      border: none;
      padding: 10px 0;
      &:first-child {
        padding: 0 20px;
      }
    }
  }

  tr.detalhes {
    td {
      background-color: #f2fbfe;
      font-weight: normal;
      color: #353535;
      font-size: 15px;
      padding-right: 20px;

      .divisao {
        padding-top: 10px;
        border-bottom: 1px #e8e8e8 solid;
      }

      .detalhe-edital-contrato {
        display: grid;
        grid-template-rows: 1.5em 2em auto;

        span {
          line-height: 14px;
          font-weight: 600;
        }
      }

      .header {
        font-weight: bold;
        color: $gray;
      }

      .contrato-detalhe {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 30% 70%;

        .nome-unidade {
          color: #217cb2;
        }
      }
    }
  }
}

.form-section-terceirizada {
  .section-nutri-crn {
    display: grid;
    grid-template-columns: 60% 38% 2%;
    grid-column-gap: 2%;
    .trash {
      cursor: pointer;
      margin-top: auto;
    }
  }
  .section-nutri-contato {
    display: grid;
    grid-template-columns: 34% 64%;
    grid-column-gap: 2%;
  }
}

.botao-direita {
  span {
    float: right;
  }
}

.secao-distribuidor {
  header {
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 14px;
    color: #353535;
  }
}
