@import "../../../../styles/variables.scss";

.input-horario {
  height: 32px;

  .ant-picker,
  .ant-picker-focused {
    width: 100%;
  }

  span.required-asterisk {
    color: $red;
    padding-right: 5px;
  }

  .invalid-field {
    input:not(:focus) {
      box-shadow: none;
      border-color: $red;
    }
  }

  .col-form-label {
    font-size: 14px;
  }

  .ant-time-picker {
    width: 100%;

    .ant-time-picker-input {
      height: 38px;
    }

    span {
      cursor: normal !important;
      pointer-events: none !important;
    }

    i {
      cursor: normal !important;
      pointer-events: none !important;
    }
  }
}
