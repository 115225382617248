@import "styles/variables.scss";

.card {
  .card-body {
    .titulo-section {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #198459;
    }

    .float-end {
      float: right;
    }

    .importante-section {
      margin-top: 10px !important;
      margin-bottom: 0px !important;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      color: #a50e05;
    }

    .importante-infobox {
      border: 1px solid #a50e05;
      border-radius: 4px;
      margin-top: 7px !important;
      padding: 9.5px !important;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #a50e05;
    }

    .info-calorias {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: #42474a;
    }
  }
}

table.table-ficha-identificacao-produto {
  thead {
    tr {
      background-color: $grayBackground;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid $grayDisabled;
      color: $blackInput;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.25px;

      th {
        padding: 1em;
        &:not(:first-child) {
          border-left: 1px solid $grayDisabled;
        }
      }
    }
  }

  tbody {
    tr {
      border: 1px solid $grayDisabled;
      border-top: none;
      color: $blackSubtle;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      align-items: center;

      td {
        padding: 0.5em;
        &:not(:first-child) {
          border-left: 1px solid $grayDisabled;
        }
      }
    }

    tr.total-faixas-cei {
      background-color: $grayBackground;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid $grayDisabled;
      color: $blackInput;
    }
  }
}

.arquivo-produto-ficha {
  width: 150px;
  height: 150px;
}

.justificativa-ficha-produto {
  background-color: #dadada;
  opacity: 1;
  font-size: 14px;
  font-weight: bold !important;
  font-family: Roboto !important;
  color: #42474a;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  border: solid 1px #dadada;
}

.no-margin {
  p {
    font-size: 14px;
    font-weight: bold !important;
    font-family: Roboto !important;
    color: #42474a;
    margin-bottom: 0px !important;
  }

  .print-button {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.radio-wrapper {
  padding-top: 5px;
}

.container-radio input:checked ~ .checkmark {
  background-color: #198459 !important;
}
