@import "styles/variables.scss";

.tabela-carga-usuarios {
  margin-top: 10px;

  header {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #198459;
  }

  article {
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e8eaf3;
    box-sizing: border-box;
    margin: 10px 0 15px 0;

    .grid-table {
      min-height: 48px;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      display: grid;
      grid-template-columns: 0.7fr 0.4fr 0.2fr 0.4fr 0.2fr;

      div {
        word-break: break-word;
      }
    }

    .header-table {
      background: #f0f0f0;
      color: #42474a;
      font-weight: bold;
      div {
        text-align: left;
        display: grid;
        align-items: center;
        height: 100%;
        padding-left: 8px;
        &:not(:last-child) {
          border-right: 1px solid #e8eaf3;
        }

        &:last-child {
          border-right: 0px;
        }
      }
    }

    .body-table {
      font-weight: 500;
      color: #333333;

      .fas {
        font-weight: 900;
        font-size: 16px;
        line-height: 18px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
      }

      .verde {
        color: $green;
      }

      .vermelho {
        color: $red;
      }

      .laranja {
        color: $orange;
      }

      button {
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
      }

      .flex-container {
        display: flex;
        justify-content: space-evenly;
        padding-left: 0;
      }

      .center {
        justify-content: center;
      }

      div {
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e8eaf3;
        text-align: left;
        padding-left: 8px;
        &:not(:last-child) {
          border-right: 1px solid #e8eaf3;
        }
      }

      div:last-child {
        border-right: 0px;
      }
    }
  }
}
