@import "styles/variables.scss";

.alinha-centro {
  display: flex;
  justify-content: center;
}

.mh-50 {
  min-height: 50vh;
}
.mh-60 {
  min-height: 60vh;
}

.mh-70 {
  min-height: 70vh;
}

.text-logo-sigpae-loader {
  font-size: 10px;
  color: $green;
}
