.card-produtos-logistica {
  min-height: 600px;
  margin-bottom: 18em !important;

  .produtos-logistica {
    hr {
      margin: 2rem 0;
    }
  }
}
