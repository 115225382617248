.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff !important;
  border-bottom: 1px solid #e3e6f0;
  color: #035d96;
  font-weight: bold;
  letter-spacing: 0.01em;
  height: 4em;
}
.select-all {
  .checkbox-label {
    margin-left: 20px;
    padding-top: 8px;
    padding-left: 20px;
  }
  .checkbox-custom {
    margin-left: 20px;
  }
}

.td-check {
  .checkbox-label,
  .checkbox-custom {
    top: unset;
    position: relative;
    padding-right: 20px;
  }
}
