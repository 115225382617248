@import "../../styles/variables.scss";

.faq-screen {
  margin-top: 5rem;
  .no-search-results {
    min-height: 25px;
    font-size: 14px;
    font-style: italic;
    span {
      cursor: pointer;
      text-decoration: underline;
      font-style: normal;
    }
  }

  .tab-content {
    margin-top: 1rem;
  }

  .page-title-container {
    display: flex;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .page-title {
    padding-top: 1.5rem;
    h4 {
      font-weight: 500;
      font-size: 24px;
      color: $black;
    }
  }

  .search-input-container {
    background-color: #f5f6f8;
    position: relative;
    #search-input {
      background-color: transparent;
      border: none;
      width: 100%;
      height: 45px;
      font-size: 14px;
      padding-left: 10px;
      color: $blackInput;
      &::placeholder {
        color: $grayLight;
      }
    }
    .fas {
      color: $grayLight;
      position: absolute;
      right: 10px;
      top: 15px;
      pointer-events: none;
    }
  }
}
