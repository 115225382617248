.form-filtros-rel-quant-diag-dieta-esp {
  .botoes-envio {
    padding-top: 35px;
    display: flex;
    justify-content: flex-end;
  }
}
.checkbox-custom {
  padding-right: 10px;
}
