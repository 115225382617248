@import "styles/variables.scss";

div.registrar-nova-ocorrencia {
  min-height: 73vh;

  section.tipo-ocorrencia {
    font-size: 14px;

    .required-asterisk {
      color: $red;
    }

    div.title-label {
      color: $gray;
    }

    div.box-descricao {
      background-color: $grayBackgroundTr;
      border: 1px solid $grayBorderIMR;
      border-radius: 0.5em;
      padding: 1em;
    }

    h2 {
      color: $green;
      font-size: 14px;
      font-weight: bold;
    }

    .parametrizacao-tipo-radio {
      label {
        color: #42474a;
        margin-left: 0.5em;
      }

      input[type="radio"] {
        accent-color: $green !important;
      }
    }

    hr {
      border-bottom: 1px solid $gray;
    }
  }
}
