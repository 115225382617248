// COLORS
$black: #000000;
$blackInput: #42474a;
$blackSubtle: #333333;
$blue: #035d96;
$blueBackgroundToastInfo: #d1ecf1;
$blueBackgroundToastPrimary: #cce5fd;
$blueButtonBackground: #064f79;
$blueButtonFont: #086397;
$blueCheckAndRadio: #007bff;
$blueIcon: #334d6e;
$blueMI: #2f80ed;
$blueSelected: #f2fbfe;
$blueToastInfo: #0c5460;
$bordeaux: #864241;
$gray: #686868;
$grayBackground: #fafafa;
$grayBorder: #e9eaea;
$grayBorderIMR: #a6a6a6;
$grayInputBorder: #d1d3e2;
$grayCard: #eeeeee;
$grayDisabled: #dadada;
$grayHeader: #929394;
$grayLightHeader: #f5f6f8;
$grayIcon: #c2cfe0;
$grayInputBackground: #e8e8e8;
$grayLight: #a8a8a8;
$grayPlaceholder: #a4a4a4;
$grayBackgroundTr: #f0f0f0;
$green: #198459;
$greenDark: #0c6b45;
$greenLight: #20aa73;
$greenBackgroundToastSuccess: #d4edda;
$greenBackgroundTable: #acd2b1;
$greenButtonHover: #0c6b45;
$greenHover: #00c855;
$greenPanel: #297805;
$greenVeryLight: #e6f6ea;
$greenToastSuccess: #155724;
$orange: #d06d12;
$orangeLight: #de9524;
$placeholder: #ced4da;
$purple: #9b51e0;
$red: #a50e05;
$redAlert: #e21017;
$redCard: #b40c02;
$redMI: #b53b0f;
$redToastError: #721c24;
$redBackgroundToastError: #f8d7da;
$yellow: #fdc500;
$yellowBackgroundToastWarning: #fff3cd;
$yellowToastWarning: #856404;
$white: #ffffff;
$whiteBackground: #f8f5f5;
$grayParagraph: #a4a4a4;
