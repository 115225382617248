.card-notificacoes {
  margin-bottom: 15em !important;
  color: #42474a;

  .notificacoes {
    .menu-inferior {
      font-size: 14px !important;
    }
  }
}
