@import "../../../styles/variables.scss";

.autocomplete {
  width: 100%;
  height: 37px;

  .ant-input-search-large .ant-input-search-button {
    height: 37px;
  }

  .ant-input {
    border-radius: 0.35rem !important;
  }

  .ant-select-selection-placeholder {
    font-weight: bold !important;
    color: $grayPlaceholder !important;
    padding: 0.375rem 0.75rem !important;
  }
}
