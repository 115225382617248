.form-filtros-ativas-inativas {
  .botao-enviar > button,
  [type="submit"] {
    margin-bottom: 0.5em;
  }
  .botao-enviar {
    display: flex;
    align-items: flex-end;
  }
}

.input-busca-dre[disabled] {
  color: #6e707e !important;
  background-color: #eaecf4 !important;
}

.input-busca-dre[disabled] {
  color: #6e707e !important;
  background-color: #eaecf4 !important;
  background-image: none !important;
}

.input-busca-escola[disabled] {
  color: #6e707e !important;
  background-color: #eaecf4 !important;
}

.input-busca-escola[disabled] {
  color: #6e707e !important;
  background-color: #eaecf4 !important;
  background-image: none !important;
}

.input-busca-aluno {
  width: 100%;
  margin-top: 2px;
  z-index: 0 !important;
}

.input-busca-aluno .ant-input[disabled] {
  color: #6e707e !important;
  background-color: #eaecf4 !important;
}

.auto-complete-field {
  label {
    margin-bottom: -3px !important;
  }
}
