@import "../../../styles/variables.scss";

.weekly {
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
  }
  .week-circle {
    height: 25px;
    width: 25px;
    padding-left: 7px;
    margin-left: 5px;
    color: #686868;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  .week-circle-clicked {
    height: 25px;
    width: 25px;
    padding-left: 7px;
    margin-left: 5px;
    color: #fff;
    background-color: #686868;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    &.green {
      background-color: $green;
    }
  }
}

.cancelado {
  .weekly {
    .week-circle {
      text-decoration: line-through;
    }

    .week-circle-clicked.green {
      background-color: $gray;
      text-decoration: line-through;
    }
  }

  .nome-periodo-escolar-relatorio-sol-alim,
  .tipos-alimentacao-relatorio-sol-alim,
  .numero-alunos-relatorio-sol-alim,
  .observacao-relatorio-sol-alim {
    p {
      color: $grayLight;
      font-weight: bold;
      text-decoration: line-through;
    }
  }
}
