.card-gestao-inconsistencias {
  margin-bottom: 15em !important;
}

.gestao-inconsistencias {
  .menu-inferior {
    font-size: 14px !important;
  }
}
.button-container-inconsistencias {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
