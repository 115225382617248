@import "../../../../../styles/variables.scss";

div.cadastro-produto-step1 {
  .card-title {
    padding-top: 1.5em;
    font-weight: bold;
    font-size: 14px;
    color: $blackInput;
  }
  .link-with-student {
    .container-radio {
      input {
        &:checked ~ .checkmark {
          background-color: $green;
        }
      }
    }
    div.label {
      span.required-asterisk {
        color: $red;
        padding-right: 5px;
      }
      color: $gray;
      padding-top: 0.5em;
      padding-bottom: 1em;
    }
    label.container-radio {
      color: $blackInput;
    }
  }
  .card-warning {
    border-radius: 4px;
  }
}

.label-formulario-produto {
  display: flex;
  color: #686868;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 10px;
  nav {
    color: #a50e05;
    padding-right: 5px;
  }
}

.adicionar-marca-fornecedor {
  display: flex;
  justify-content: left;
  align-items: center;

  .botao-adicionar-marca-fabricante {
    height: 38px;
    margin-top: 5px;
  }
}

.field-modal-produto {
  margin: 1em 1.5em;
}
