@import "styles/variables.scss";

.card.solicitation {
  min-height: 60vh;
  .card-title {
    color: $blackInput;
  }
  .table-titles {
    font-size: 14px;
    padding-left: 15px;
    color: $gray;
    .type-food {
      padding-left: 20px;
    }
    div {
      padding: 15px 0;
    }
  }
  .period-quantity {
    border-radius: 7px;
    &.number-0 {
      background-color: #fff7cb;
    }
    &.number-1 {
      background-color: #ebedff;
    }
    &.number-2 {
      background-color: #ffeed6;
    }
    &.number-3 {
      background-color: #eaffe3;
    }
    &.number-4 {
      background-color: #e4f1ff;
    }
    &.number-5 {
      background-color: #d6ffea;
    }
  }

  section {
    margin: 0.5em 0 1.5em 0;
    grid-row-gap: 2em;

    .grid_principal {
      display: grid !important;
      grid-template-columns: 69% 29%;
      grid-column-gap: 2%;
    }

    .grid-motivo-continuo {
      display: grid !important;
      grid-template-columns: 28% 28% 38%;
      grid-column-gap: 2%;
    }

    .display {
      display: none;
    }

    .grid-outro-motivo {
      display: grid;
      grid-template-columns: 100%;

      .error-msg {
        color: #a50e05;
        font-size: 12px;
      }

      div {
        padding: 0 !important;
      }
    }
  }
}

.badge-sme {
  &.badge-secondary-sme {
    background-color: #d9d8d8;
  }
}

.report {
  .red {
    color: #fc2122;
  }

  .dot {
    height: 15px;
    width: 15px;
    background-color: #42474a;
    border-radius: 50%;
    display: inline-block;

    &.red {
      background-color: #fc2122;
    }
  }

  .title-message {
    color: $gray;
    font-size: 14px;
    font-weight: bold;

    &.green {
      color: $green;
    }

    &.yellow {
      color: $yellow;
    }

    &.red {
      color: $red;
    }
  }

  table.table-periods {
    margin-top: 20px;
    tr {
      color: $gray;
      margin-top: 20px;
      font-size: 14px;
      th {
        font-weight: normal;
        &:not(:first-child) {
          padding-left: 40px;
        }
      }
      td {
        font-weight: bold;
        color: $blackInput;
        &:not(:first-child) {
          padding-left: 40px;
        }
      }
    }
  }
}

.info-inclusao {
  .titulo {
    margin-bottom: 0.5em;
  }
  .inclusoes {
    margin-top: 0.5em;
    width: 50%;
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.conteudo {
  margin: 0.5em 1em 2em 1em;
}

.dropdown-heading-value {
  line-height: 38px !important;
  position: relative !important;
}

.quantidade-aluno {
  height: 40px !important;
}

.tabela-faixa-etaria-qtd-alunos {
  border-radius: 4px;
  border: 1px solid #e9eaea;
  margin: 2em 0 2em 0;

  article {
    display: grid;
    grid-template-columns: 7fr 2fr 2fr;
    border-bottom: 1px solid #e9eaea;
    min-height: 48px;
    height: auto;
    font-weight: 500;
    line-height: 18px;
    color: #686868;

    div {
      font-style: normal;
      font-size: 14px;

      /* identical to box height */

      display: flex;
      align-items: center;
      justify-content: center;
    }

    div:first-child {
      justify-content: flex-start;
      margin-left: 15px;
    }

    .faixa-etaria {
      border-right: 0.5px solid #e9eaea;
    }
    .alunos-matriculados {
      border-left: 0.5px solid #e9eaea;
      border-right: 0.5px solid #e9eaea;
    }
    .quantidade {
      display: flex;
      border-left: 0.5px solid #e9eaea;
    }
  }

  article:first-child {
    background-color: #f5f6f8;
    font-weight: bold;
    line-height: 16px;
    color: #42474a;
  }

  article:last-child {
    background-color: #f5f6f8;
    font-weight: bold;
    line-height: 16px;
    color: #42474a;
    border-bottom: 0px;
  }
}
