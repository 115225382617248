.radio-button-sigpae {
  .label-radio {
    margin-bottom: 6px;
  }

  .container-radio {
    display: inline-block;
    margin-right: 16px;
    padding-left: 28px;
    padding-bottom: 16px;
  }
}
