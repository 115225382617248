.filtros-cadastros {
  padding-bottom: 40px;

  .filtro-inputs {
    height: 40px !important;
    .ant-input {
      height: 40px;
    }
  }
}
