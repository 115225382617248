@import "../../../styles/variables.scss";

.checkbox-component {
  .checkbox-label {
    color: $gray;
    font-size: 14px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked {
        ~ .checkbox-custom {
          background-color: $blueCheckAndRadio;
          border-radius: 5px;
          transform: rotate(0deg) scale(1);
          opacity: 1;
          border: 1px solid $blueCheckAndRadio;
          &:after {
            transform: rotate(45deg) scale(1);
            opacity: 1;
            left: 7px;
            top: 2px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            background-color: transparent;
            border-radius: 0;
          }
          &.small {
            border-radius: 4px;
            &:after {
              transform: rotate(45deg) scale(0.7);
              left: 4px;
              top: 0px;
              width: 6px;
              height: 11px;
            }
          }
        }
      }
    }
    .checkbox-custom {
      position: absolute;
      top: 0;
      left: 0px;
      height: 20px;
      width: 20px;
      &.small {
        height: 14px;
        width: 14px;
      }
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid $gray;
      cursor: pointer;
      &:after {
        position: absolute;
        content: "";
        left: 12px;
        top: 12px;
        height: 0px;
        width: 0px;
        border-radius: 5px;
        border: solid $blueCheckAndRadio;
        border-width: 0 3px 3px 0;
        transform: rotate(0deg) scale(0);
        opacity: 1;
      }
      &.radio {
        border-radius: 15px;
      }
    }
  }
}

.checkbox-label,
.checkbox-custom {
  top: unset;
  position: relative;
  padding-right: 20px;
  &.small {
    padding-right: 0px;
  }
}
