@import "styles/variables.scss";

.card-title-status {
  font-weight: 600;
  font-size: 13px;
  color: $blackInput;
  letter-spacing: 0.01em;
  padding-top: 5px;
  &.card-com-href {
    cursor: pointer;
  }
  span {
    color: $grayPlaceholder;
    font-weight: normal;
    font-size: 10px;
    padding-right: 5px;
  }
}

.card-panel {
  border-left: 10px solid !important;
  i {
    padding: 0 10px;
  }
  hr {
    margin-top: 5px;
  }
  p.data {
    padding: 0 10px;
    font-weight: 700;
    font-size: 12px;
    color: #707683;
    margin-bottom: 0.3rem;
    span {
      font-weight: normal;
    }
    .dietas-abertas {
      font-size: 11px;
      font-weight: bold;
      background-color: #198459;
      color: white;
      padding: 2px 6px 0px 6px;
      border-radius: 50%;
    }
    .qtd-dois-digitos {
      padding: 2px 4px 0px 3px;
    }
  }
  p.conferida {
    color: #198459 !important;
  }
  a.see-more {
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    color: $blue;
    padding: 10px 0;
  }
}

.ajuste-icones {
  display: flex;
  justify-content: space-between;
  margin-right: 0.5em;
  img {
    height: 1em;
  }
}

.container-link {
  display: flex;
  justify-content: center;
}
