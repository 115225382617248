@import "../../../styles/variables.scss";

.div-input-search {
  .btn {
    padding: 10px 20px;
    font-weight: bold;
    font-size: 14px;
    i {
      padding-right: 10px;
    }
  }
  input.input-search {
    border: none;
    border-bottom: 1px solid $grayLight;
    &::placeholder {
      padding-right: 10px;
      color: $grayLight;
      font-style: italic;
    }
  }
}

.container-input {
  display: flex;
  justify-content: flex-end;
}

.warning-num-charac {
  font-size: 10px;
  color: $gray;
  text-align: right;
}

.produtos-edital {
  .ant-select-selector {
    border-radius: 0.35rem !important;
    margin-top: 0 !important;
    height: 35px !important;
  }
}
