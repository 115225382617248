@import "styles/variables.scss";

.conferencia-detalhe {
  color: black;

  .fas {
    margin-right: 4px;
  }

  .fas.ocorrencia {
    color: $orange;
  }

  .fas.conferencia {
    color: $green;
  }

  .table-detalhe {
    color: $blackInput;
    th {
      background-color: $greenLight;
      color: $white;
      padding: 6px 4px 6px 8px;
      font-size: 14px;

      .text-center {
        padding: 6px 0px;
      }
    }

    .nome-alimento {
      width: 20%;
    }

    .embalagem {
      width: 8%;
    }

    .recebimento {
      width: 10%;
    }

    td {
      font-size: 14px;
    }

    .green {
      color: #297805;
    }

    .red {
      color: #b40c02;
    }
  }

  .relatorio-conferencia {
    p {
      display: inline-block;
      margin: 8px 0;
      font-size: 14px;
    }

    .titulo-recebimento {
      font-size: 18px;
      margin: 16px 0;
    }

    .titulo-alimento {
      font-weight: bold;
      color: $green;
      font-size: 16px;
    }

    .titulo-recebimento {
      color: $green;
      font-weight: bold;
    }

    .dado-conferencia {
      font-weight: bold;
    }

    .dado-conferencia {
      padding-left: 2px;
    }
  }
}
