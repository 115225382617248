svg.ck.ck-icon.ck-reset_all-excluded.ck-icon_inherit-color.ck-dropdown__arrow {
  z-index: 0 !important;
}

.ant-btn-primary {
  background-color: transparent !important;
  border: 1px solid #198459 !important;
  color: #198459 !important;
  font-weight: 900 !important;
  height: 40px !important;
  border-radius: 5px !important;
}

.ant-btn-primary:hover {
  border: 1.5px solid #198459 !important;
  color: #ffffff !important;
  background-color: #198459 !important;
}

.ant-btn-default {
  display: none !important;
}
