@import "../../../styles/variables.scss";

.breadcrumb-title {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #c8c8c8;
}

.page-title {
  margin-top: 0.5em;

  .texto-titulo {
    vertical-align: -webkit-baseline-middle;
    vertical-align: -moz-middle-with-baseline;
  }
}

.page-no-sidebar-card {
  min-height: 500px;
}

#wrapper {
  background-color: $whiteBackground;
}

#content-wrapper {
  div.content-wrapper-div {
    margin-left: 17rem;
    &.toggled {
      margin-left: 5.5rem;
    }
  }
}

.dark-content {
  #wrapper {
    #content-wrapper {
      background-color: #464646;
      div.content-wrapper-div {
        background-color: #464646;
      }
    }
  }

  .page-title {
    color: #fff;
  }
}
.modal-botao-voltar {
  .modal-dialog {
    max-width: 600px !important;
  }
}
