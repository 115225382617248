article {
  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #42474a;
    margin-bottom: 0.5em !important;
    margin-top: 0.5em !important;
  }

  .obrigatorio {
    color: #eb5757;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}

.tres-colunas-modal {
  display: grid;
  grid-template-columns: 66% 15% 15%;
  column-gap: 2%;

  .ant-time-picker-input {
    height: 38px;
  }

  .ant-time-picker-clock-icon {
    color: #198459;
  }
}

.rodape-botoes {
  button {
    margin-left: 1em;
  }
}

.campo-hora {
  .ant-form-explain {
    color: #a50e05;
    font-size: 12px !important;
  }
}

.ant-form-explain {
  color: #a50e05 !important;
  margin-top: 5px;
  font-size: 12px !important;
}
