@import "../../../styles/variables.scss";

.modal-question {
  p.title {
    font-size: 14px;
    color: $blackSubtle;
  }
  textarea::placeholder {
    text-align: left;
  }
  div.textarea {
    height: 340px;
  }
  .numero-protocolo {
    display: flex;
    justify-content: flex-end;
    margin-right: 1em;
    font-weight: bold;
    color: #000000;
  }
}
