.data_inicio {
  label {
    font-weight: 500 !important;
    color: #686868 !important;
  }
}

.filtros-conferir-entrega {
  padding-bottom: 40px;

  .input-numero-guia {
    height: 40px;
  }

  .data-inicial {
    height: 40px !important;
  }

  .data-final {
    height: 40px !important;
  }
}
