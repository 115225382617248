@import "../../../../styles/variables.scss";

.input-file {
  text-align: right;
  input.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
  }
  .upload-button {
    margin-top: auto;
  }
  .file-div {
    margin: 0;

    .file-name-container {
      font-size: 14px;
      margin-top: 6px;
      height: 24px;
      color: $blue;
      background: #f3f3f3;
      width: auto;
      display: inline-block;

      .file-name {
        margin-left: 6px;
        margin-right: 36px;
        &:hover {
          text-decoration: underline;
        }
      }

      .fas {
        color: #6f777c;
        font-size: 16px;
        padding-top: 4px;
      }
    }
    .exclude-icon {
      i {
        &:hover {
          color: $red;
        }
      }
    }
  }
}

.align-left {
  text-align: left !important;
}

.delete {
  margin-left: 2em;
  font-weight: bold;
  background-color: $blue;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: $blue;
  }
}

.card-warning {
  border: 1px solid $red;
  color: $red;
  font-size: 10px;
  padding: 10px;
}

.anexos {
  margin-top: 3%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.link {
  color: #086397 !important;

  &.editar-lotes {
    cursor: pointer;
    text-decoration: underline !important;
  }
}

.link:hover {
  text-decoration: underline !important;
}

.arquivos-anexados {
  background: #f3f3f3;
}
