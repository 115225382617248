//Agradecimentos a: http://dsgov.estaleiro.serpro.gov.br/ds/componentes/breadcrumb

@import "../../../styles/variables.scss";

.breadcrumb-row {
  margin-top: -1.5rem;
  .br-breadcrumb {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    .home a {
      color: $blackSubtle;
      &:hover,
      &:focus {
        color: $blue;
        text-decoration: none;
      }
    }
    li {
      color: $blackSubtle;
      display: inline;
      font-size: 13px;
      font-weight: 500;
      a {
        color: $blackSubtle;
        font-weight: 500;
        text-decoration: none;
        &:hover {
          color: inherit;
          text-decoration: underline;
        }
      }
      .home {
        padding-right: 10px;
      }
    }
    .is-active {
      color: $greenDark;
      font-weight: 600;
      a {
        color: $greenDark;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }

  .br-breadcrumb li + li::before {
    color: #9e9d9d;
    font-weight: 900;
    content: "\f138";
    font-family: "Font Awesome 5 Free";
    font-size: 12px;
    padding: 0 12px;
  }

  .contrast {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: $blackSubtle;
  }
}

.dark-content {
  .contrast {
    color: $white;
  }
  .br-breadcrumb {
    li {
      a {
        color: $white;
      }
    }
    .is-active {
      color: $white;
    }
  }
}
