@import "../../styles/variables.scss";

.btn-remover {
  margin-top: 30px !important;
  height: 35px !important;
}

.card.inversao-dia-cardapio {
  .card-title {
    color: $blackInput;
  }

  .input-group {
    .input-group-text {
      height: 35px;
      margin-top: 2px;
      font-size: 14px;
    }
    &:not(.active-calendar) {
      .input-group-text {
        color: $gray;
      }
    }
    &.active-calendar {
      .input-group-text {
        height: 37px;
      }
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 98%;
  }

  .for-span {
    font-size: 13px;
    color: $blackInput;
    margin-top: 40px;
  }
}
