@import "../../../../styles/variables.scss";

.card-pendency-approval.unified-solicitation {
  padding: 16px 44px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .card-title {
    font-weight: bold;
    font-size: 18px;
    &.priority {
      color: $red;
    }
    &.on-limit {
      color: $orange;
    }
    &.regular {
      color: $greenPanel;
    }
  }
  .order-box {
    display: grid;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border: 0.5px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 4px;
    &.priority {
      background: $red;
    }
    &.on-limit {
      background: $orange;
    }
    &.regular {
      background: $greenPanel;
    }
    span {
      font-weight: bold;
      font-size: 34px;
      text-align: center;
      color: #ffffff;
    }
    .order {
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  .order-lines {
    .text {
      font-size: 14px;
      color: $blackInput;
    }
  }
}

.orders-table-unified-solicitation {
  width: 100%;
  tr {
    margin-left: 0;
    th {
      font-weight: normal;
      font-size: 14px;
      color: #686868;
    }
    td {
      font-weight: bold;
      font-size: 15px;
      color: #353535;
      border-bottom: 1px solid #c0c0c0;
      padding: 10px 0;
      &:nth-child(1),
      &:nth-child(2) {
        padding-left: 1rem;
      }
      &:nth-child(3) {
        padding-left: 1.2rem;
      }
      &:nth-child(4) {
        padding-left: 1.5rem;
      }
    }
  }

  thead,
  tbody {
    display: block;
  }
  tbody {
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.fa-search {
  &.inside-input {
    z-index: 1;
    padding-top: 12px;
    color: #2881bb;
    font-size: 20px;
    position: relative;
  }
}
