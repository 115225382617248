.card-cadastro-produto {
  .form-control {
    height: 38px;
  }

  .titulo-verde {
    color: #0c6b45;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  }
}
