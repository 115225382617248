div.modal-info-cronograma {
  color: #42474a;

  div.modal-content {
    margin-top: 25vh;
    min-height: 30vh;

    .row {
      margin-bottom: 16px !important;
    }
  }
}
