.data_inicio {
  label {
    font-weight: 500 !important;
    color: #686868 !important;
  }
}

.filtros-solicitacao-alteracao {
  padding-bottom: 40px;
}
