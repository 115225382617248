@import "styles/variables.scss";

.componente-motivo-da-recusa-de-homologacao {
  color: #42474a;

  .titulo-recusa {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2em;
  }

  .texto-wysiwyg-recusa {
    border: 1px #dddddd solid !important;
    padding: 0.5rem;
    font-size: 12px;
    border-radius: 2px;
  }
}
