@import "../../../styles/variables.scss";

.navbar {
  height: 70px;
  .nav-bar {
    &:not(.toggled) {
      padding-left: 3rem;
    }
    &.toggled {
      margin-left: -2rem;
    }
    img {
      height: 55px;
      width: auto;
    }
    .marca-d-agua {
      width: 135px;
      height: 40px;
    }
  }
  .navbar-nav {
    padding-top: 0.5rem;
    .nav-item {
      padding-left: 30px;
      img {
        height: 28px;
      }
      p {
        text-align: center;
        color: $grayHeader;
        font-size: 10px;
      }
      .icone-verde {
        font-size: 14px;
        color: #fff;
      }
      .icone-verde-fundo {
        background-color: $green;
        border-radius: 67px;
        border: 0px;
        padding: 7px;
        font-size: 11px;
        height: 28px;
        width: 28px;
        margin: auto;
      }
    }
  }
}

.dark-content {
  .navbar {
    background-color: #202020 !important;
    .navbar-nav {
      .nav-item {
        p {
          color: #fff;
        }

        .icone-verde-fundo {
          background-color: #e7e7e7;

          .icone-verde {
            color: #3a3a3a;
          }
        }
      }
    }
  }
}
