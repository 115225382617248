@import "../../../styles/variables.scss";

.navbar-notificacoes {
  .span-notificacoes-menor-que-10,
  .span-notificacoes-maior-que-10 {
    position: absolute;
    background-color: #b40c02;
    border-radius: 67px;
    font-size: 11px;
    color: #fff;
    display: block;
    margin-left: 12px;
    margin-top: -32px;
  }

  .span-notificacoes-maior-que-10 {
    padding: 3px 5px;
  }

  .span-notificacoes-menor-que-10 {
    padding: 2px 7px;
  }
}

.menu-notificacoes {
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 12px;
  min-width: 360px;
  width: 20vw;
  z-index: 1;

  .nao-lida > td {
    background: #cfe8de;
    &.status {
      color: #b40c02;
      text-transform: uppercase;
    }
  }

  .lida > td {
    background: white;
  }

  .tr-notificacoes {
    cursor: pointer;
    border: 1px solid #dadada;
    height: 50px;
    &:first-child {
      th,
      td {
        border-top: 0 none;
      }
    }
    th,
    td {
      border-color: #dadada;
      padding-bottom: 0.5rem !important;
      padding-top: 0.5rem !important;

      font-weight: bold;
      color: #42474a;
    }
  }
}
