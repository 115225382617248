.tabela-conferencia-guia {
  thead {
    background: #198459;
    color: white;

    .header-capacidade {
      background: #20aa73;
    }

    th {
      border: 1px solid #e0e0e0;
      letter-spacing: 0.02rem;
    }
  }
}
