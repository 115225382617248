@import "../../../../../../styles/variables.scss";

.card-detalhar-documentos-recebimento-codae {
  color: $blackInput;
  font-size: 14px;

  .subtitulo {
    color: $greenDark;
    font-size: 16px;
    font-weight: bold;
    margin: 8px 0px;

    &.laranja {
      color: $orange;
    }
  }

  .subtitulo-documento {
    color: $greenDark;
    font-size: 15px;
    font-weight: bold;
    margin: 12px 0px;
  }

  .flex-header {
    display: flex;
    justify-content: space-between;
  }

  .status {
    border-radius: 50px;
    padding: 2px 8px;
    height: 10%;
    margin: auto 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;

    &.aprovado {
      background-color: $green;
      border: 1px solid $greenDark;
      color: white;
    }

    &.correcao {
      background-color: white;
      border: 1px solid $orange;
      color: $orange;
    }

    i {
      margin-right: 8px;
    }
  }
}
