.card-cadastro-layout-embalagem {
  .subtitulo {
    color: #0c6b45;
    font-size: 16px;
    font-weight: bold;
    margin: 8px 0px;

    .fa-question-circle {
      color: gray;
    }
  }

  .label-imagem {
    font-size: 12px;
    margin: 0 !important;
  }
}
