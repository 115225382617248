@import "styles/variables.scss";

form.special-diet {
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
  }

  .cinza-escuro {
    color: $gray;
  }

  div.foto-legenda {
    font-size: 0.6rem;
    margin-top: 0.5em;
  }

  .grid-container {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 20% 50% 26%;
    column-gap: 2%;
    row-gap: 0.5em;
  }

  .ajuste-fonte {
    font-size: 14px;
    font-weight: 500;
    color: #686868;

    span {
      color: $red;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    .bloco-nome-data {
      padding-left: 3em;
    }
  }
  @media screen and (min-width: 1401px) and (max-width: 1600px) {
    .bloco-nome-data {
      padding-left: 2em;
    }
  }

  .attachments {
    .text {
      font-size: 14px;
      color: #686868;
    }

    .card-warning {
      border: 1px solid $red;
      color: $red;
      font-size: 10px;
      padding: 10px;
    }

    .btn {
      display: flex;
      margin-top: 2em;
      justify-content: flex-end;
    }
  }
}

.color-disabled {
  background-color: #dadada !important;
}

div.current-diets {
  font-size: 14px;
  .no-diets {
    color: $red;
  }
  p.title {
    font-style: normal;
    color: $gray;
  }
  div.school-container {
    hr {
      margin: 0;
    }
    div.title {
      color: $blackSubtle;
      font-weight: bold;
    }
    div.container {
      padding-left: 1em;
      table.table-periods {
        color: $gray;
      }
    }
  }
}

div.card {
  &.opaco {
    opacity: 0.5;
  }
}
