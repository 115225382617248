@import "styles/variables.scss";

div.multi-select {
  .multiselect-wrapper-disabled .dropdown-heading {
    pointer-events: none;
    background-color: #e9ecef !important;
  }

  .multiselect-wrapper-disabled
    .dropdown
    .dropdown-heading
    .dropdown-heading-value
    span {
    color: #5d515b !important;
  }
}
.erro-borda {
  .dropdown-heading {
    border-color: $red !important;
  }
}
label.select-item {
  white-space: nowrap;
}

body {
  background-color: yellow;
}
