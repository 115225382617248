@import "styles/variables.scss";

table.table-periods-alteracao {
  width: 100%;
  tr {
    color: $gray;
    font-size: 14px;
    th {
      font-weight: normal;
    }
    td {
      font-weight: bold;
      color: $blackInput;

      .data-cancelada {
        color: $gray;
        text-decoration: line-through;
      }

      .justificativa {
        color: $redToastError;
      }
    }
  }
}
