@import "../../../../styles/variables.scss";
// .checkbox-field-component {
//   width: 20px;
//   .checkbox-field-span {
//     display: block;
//     height: 20px;
//     width: 20px;
//     background-color: #fff;
//     border-radius: 3px;
//     border: 1px solid #686868;
//     cursor: pointer;
//   }
// }
.checkbox-field-component {
  width: 20px;
  // .checkbox-field-span {
  //   display: block;
  //   height: 20px;
  //   width: 20px;
  //   background-color: #fff;
  //   border-radius: 3px;
  //   border: 1px solid #686868;
  //   cursor: pointer;
  // }
  .checkbox-label {
    color: $gray;
    font-size: 14px;
    input {
      display: none;
      &:checked {
        ~ .checkbox-field-span {
          background-color: $blueCheckAndRadio;
          border-radius: 5px;
          transform: rotate(0deg) scale(1);
          opacity: 1;
          border: 1px solid $blueCheckAndRadio;
          &:after {
            transform: rotate(45deg) scale(1);
            opacity: 1;
            left: 7px;
            top: 2px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            background-color: transparent;
            border-radius: 0;
          }
        }
      }
    }
    .checkbox-field-span {
      display: block;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid $gray;
      cursor: pointer;
      &:after {
        position: absolute;
        content: "";
        left: 12px;
        top: 12px;
        height: 0px;
        width: 0px;
        border-radius: 5px;
        border: solid $blueCheckAndRadio;
        border-width: 0 3px 3px 0;
        transform: rotate(0deg) scale(0);
        opacity: 1;
      }
      &.radio {
        border-radius: 15px;
      }
    }
  }
}
