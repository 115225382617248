@import "../../../styles/variables.scss";

.ant-btn:hover {
  background-color: transparent;
  color: $green;
  border-color: $green;
}
.container-historico {
  position: relative;
}

.footer-historico {
  position: relative;
  bottom: 0;
  right: 0;
  margin-left: 240px;
  margin-bottom: 10px;
}
.download-ocorrencias {
  color: $green;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  width: max-content;
}

.download-documento-entrega {
  color: $green;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  width: max-content;
  margin-left: -75px !important;
}

.preenchimento {
  min-height: 14.5vh;
}
