.data_inicio {
  label {
    font-weight: 500 !important;
    color: #686868 !important;
  }
}

.filtros-solicitacao-alteracao {
  padding-bottom: 40px;

  .input-busca-produto {
    height: 40px;
  }

  .input-data {
    height: 40px !important;
  }

  .multi-select {
    height: 40px !important;

    .dropdown,
    .dropdown-heading {
      height: 100% !important;
    }

    .dropdown-heading-dropdown-arrow {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
}
