.page-reclamacao-produto {
  .header-busca-produto {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #42474a;
    margin-bottom: 20px;
  }
  .label-resultados-busca {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #42474a;
  }
}
