@import "styles/variables.scss";

div.data-opcional {
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
  }
  .data-opcional-inner {
    display: flex;
    align-items: center;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $green;
  }
  .react-datepicker__close-icon {
    display: none;
  }
  .fa-calendar-alt {
    z-index: 0;
    cursor: text;
    color: $green;
    position: relative;
  }
  div.react-datepicker-wrapper {
    margin-right: -30px;
    margin-left: 10px;
    .react-datepicker__input-container {
      input {
        height: 38px;
        font-size: 14px;
        color: $blackInput;
        &.invalid-field:not(:focus) {
          box-shadow: none;
          border-color: $red;
        }
      }
    }
  }
  .container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-color: $blueCheckAndRadio;
      }
      &:checked ~ .checkmark:after {
        display: block;
      }
    }
    &:hover {
      input:not(:checked) ~ .checkmark {
        background-color: $grayInputBackground;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $white;
      border: 1px solid $grayPlaceholder;
      border-radius: 50%;
      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }
  }
  label.container-radio {
    color: $blackInput;
    &:not(:first-child) {
      margin-left: 30px;
    }
  }
}
