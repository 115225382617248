@import "styles/variables.scss";

.clausulas-desconto {
  .ant-form-item {
    flex: 1;

    & > div {
      display: flex;
      flex-direction: column;

      .ant-form-item-label {
        display: flex;

        label {
          justify-self: flex-start;
        }
      }

      .ant-input-number,
      .ant-input {
        height: 37px;
        width: 100%;
        line-height: 37px;
        margin-top: 0.4em;
      }
    }
  }

  .tabela-clausulas {
    .titulo-tabela {
      font-weight: bold;
      font-size: 14px;
      color: $greenDark;
    }

    table {
      width: 100%;
      font-size: 14px;
      color: $blackInput;

      thead {
        tr {
          background-color: $grayLightHeader;
          border: 1px solid $grayDisabled;
          border-radius: 4px 4px 0 0;
          font-weight: bold;

          th {
            padding: 0.5em;
            &:not(:first-child) {
              border-left: 1px solid $grayDisabled;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 0.5em;
            border-left: 1px solid $grayDisabled;
            border-bottom: 1px solid $grayDisabled;
            i.verde {
              cursor: pointer;
              color: $green;
            }
            &:last-child {
              border-right: 1px solid $grayDisabled;
            }
          }
          &:last-child {
            td {
              &:first-child {
                border-radius: 0 0 0 4px;
              }
              &:last-child {
                border-radius: 0 0 4px 0;
              }
            }
          }
        }

        .botao-excluir {
          background-color: transparent;
          border: none;
          height: 100%;
          display: flex;
          padding-top: 0.2rem;

          i {
            color: $green;
          }
        }
      }
    }
  }
}
