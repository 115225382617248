.st0 {
  fill: #ffffff;
}
.st1 {
  fill: #198459;
}
.st2 {
  opacity: 0.4;
}
.st3 {
  display: none;
}
.st4 {
  display: inline;
}
.st5 {
  fill: #198459;
  stroke: #198459;
  stroke-width: 0.75;
  stroke-miterlimit: 10;
}
.st6 {
  fill: #198459;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st7 {
  fill: #198459;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st8 {
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #0f1011;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st10 {
  fill: #ffffff;
  stroke: #148459;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st11 {
  fill: #148459;
}
.st12 {
  fill: #148459;
  stroke: #148459;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.svg {
  enable-background: new 0 0 106.3 105.8;
  width: 4.75rem;
  overflow: visible !important;
}
