.modal-ativacao-produto {
  .row-ativacao {
    margin-bottom: 1em;
  }
}

.multiselect-editais {
  z-index: 10;
}

.multiselect-editais .select .multi-select .dropdown-heading {
  height: 2.2rem !important;
}
