.card-notificacoes {
  .titulo {
    font-weight: 700;
  }

  .span-icone-toogle {
    background-color: rgb(199, 201, 200);
    border-radius: 67px;
    border: 1px solid rgb(199, 201, 200);
    padding: 8px 12px;
    font-size: 16px;
    color: #42474a;
  }

  #accordionNotificacoes > .card {
    border-radius: 5px;
  }

  #accordionNotificacoes > .card > .card-header {
    border-radius: 5px;
    margin-bottom: 0;
    background-color: #fff;
  }

  .descricao-notificacao {
    * {
      font-size: 16px;
    }
  }

  .card-header-notificacao-lida {
    border-left: #a4a4a4 solid 8px !important;
    p,
    span {
      color: #a4a4a4 !important;
    }
  }

  .card-header-notificacao-pendente {
    background: #b40c021a !important;
    border: 1px solid #b40c02 !important;
    border-left: #b40c02 solid 8px !important;
  }

  #accordionNotificacoes .card-header,
  #accordionNotificacoes .card-body {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    background-color: #f3f3f3;
  }

  .card-header {
    height: 110px;

    .card-header-content {
      height: 100%;
      align-content: center;
    }
  }

  .data-notificacoes {
    font-size: 20px;
    font-weight: bold;
  }

  .titulo-notificacao {
    font-weight: 700;
  }

  .remetente-categoria {
    font-size: 14px;
  }

  .span-tipo-pendencia,
  .span-tipo-informacao,
  .span-tipo-aviso,
  .span-tipo-alerta {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 16px;
    font-weight: 700;
  }

  .card-tipo-pendencia {
    border-left: #b40c02 solid 8px;
  }
  .span-tipo-pendencia {
    color: #b40c02;
  }

  .span-tipo-aviso {
    color: #0048a9;
  }
  .card-tipo-aviso {
    border-left: #0048a9 solid 8px;
  }

  .span-tipo-alerta {
    color: #d06d12;
  }

  .card-tipo-alerta {
    border-left: #d06d12 solid 8px;
  }

  .container-form-filtros {
    border-bottom: solid 1px #ccc;
  }

  .acoes-notificacoes {
    justify-content: space-between;
  }

  .ant-switch-checked {
    background-color: #198459;
  }
  .ant-click-animating-node {
    -webkit-box-shadow: 0 0 0 0 #198459;
    box-shadow: 0 0 0 0 #198459;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #198459;
    border-color: #198459;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #198459;
  }

  .ant-checkbox-wrapper {
    color: #42474a;
  }
}
