@import "styles/variables.scss";

.modal-como-acessar {
  width: 55vw !important;
  max-width: 55vw !important;
  margin: 33vh auto !important;

  text-align: center;
  .flex-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    height: 100%;

    div {
      margin: 4px 0;
    }

    .titulo {
      color: $greenButtonHover;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
    }

    .texto {
      font-size: 15px;
      line-height: 17px;
      color: $blackInput;
    }

    .icone {
      color: $green;
      font-size: 60px;
    }

    .rodape {
      padding: 8px;
      color: $blackInput;
      font-size: 12px;
      line-height: 14px;
      background-color: #f3f3f3;
    }

    .rodape-fake {
      height: 44px;
    }
  }
}

.login-bg {
  background-image: url("bg.png");
  width: 50%;
  position: absolute;
  height: 100%;
  left: 0px;
  object-fit: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

a.hyperlink {
  font-size: 14px;
  font-weight: bold;
  color: $blue;
}

.right-half {
  background-color: #fffffd;
  position: absolute;
  right: 0px;
  width: 50%;
  height: 100%;
  text-align: center;
  .container {
    position: absolute;
    left: 50%;
    height: 100%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0 135px;
    text-align: left;
    div.form {
      .login {
        div[class*="col-"] {
          padding: 0;
        }
      }

      .input {
        margin-bottom: 1rem;
      }

      .input-login {
        height: 44px;
      }

      .btn-acessar {
        height: 44px;
        margin-top: 7px;
        margin-bottom: 2.5rem;
      }

      .como-acessar {
        margin: 0;

        span {
          cursor: pointer;
          color: #035d96;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .logo-sigpae {
      display: flex;
      text-align: center;
      justify-content: center;

      flex-direction: column;

      @media screen and (max-width: 768px) {
        padding-top: 3.5rem;
        padding-bottom: 0.5rem;
        img {
          object-fit: contain;
          max-height: 95px;
          width: 100%;
          height: auto;
        }
      }
      @media screen and (min-width: 769px) {
        padding-top: 7rem;
        padding-bottom: 1rem;
        img {
          object-fit: contain;
          max-height: 120px;
          width: 100%;
          height: auto;
        }
      }

      .titulo {
        font-size: 24px;
        color: #42474a;
        font-weight: bold;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 1em;
      }
    }
    .logo-sigpae-texto {
      display: flex;
      text-align: center;
      justify-content: center;

      @media screen and (max-width: 768px) {
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        img {
          height: auto;
          width: 75px;
        }
      }
      @media screen and (min-width: 769px) {
        padding-top: 2rem;
        padding-bottom: 1rem;
        img {
          height: auto;
          width: 125px;
        }
      }
    }
    .terms {
      font-size: 12px;
      color: $blue;
    }
    span.text-terms {
      padding-left: 2rem;
    }
    .logo-prefeitura {
      text-align: center;
      padding-top: 4rem;
      img {
        height: 65px;
        width: 171px;
      }
    }
  }
}

div.text-end.back {
  color: $blue;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}

div.input-group.email-sme {
  .col-8,
  .col-4 {
    padding-left: 0;
  }

  @media screen and (min-width: 768px) {
    .col-md-6 {
      padding-right: 0;
      margin-right: -12px;
    }
  }

  select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-append {
    padding-top: 35px;
    .input-group-text {
      height: 38px;
      font-size: 14px;
    }
  }
}

.texto-simples {
  color: #42474a;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  padding: 5;
  &-verde {
    @extend .texto-simples;
    align-items: center;
    text-align: center;
    color: #198459;
  }
  &-vermelho {
    @extend .texto-simples;
    align-items: center;
    text-align: center;
    color: #c00000;
  }
  &-grande {
    @extend .texto-simples;
    align-items: center;
    text-align: center;
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
  }
}

.alerta {
  display: flex;
  justify-content: center;
  i {
    margin-right: 0.5em;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
  }
  &-vermelho {
    @extend .alerta;
    p,
    i {
      color: #c00000;
    }
  }
  &-verde {
    @extend .alerta;
    p,
    i {
      color: #198459;
    }
  }
}

.alinha-direita {
  display: flex;
  justify-content: flex-end;
}

.div-circular {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 7em;
  height: 7em;
  &-verde {
    @extend .div-circular;
    border: 3px solid #297805;
  }
  &-vermelho {
    @extend .div-circular;
    border: 3px solid #b40c02;
  }
}

.check {
  color: #42474a;
  &-verde {
    @extend .check;
    color: #297805;
  }
  &-vermelho {
    @extend .check;
    color: #b40c02;
  }
}

.alinha-centro {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: block;
    grid-auto-rows: auto;

    &:first-child {
      width: 50%;
    }
  }
}

.signup-form {
  .tabs {
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    margin-left: 1em;
    margin-right: 1em;
    .tab {
      padding-bottom: 5px;
      &.active {
        color: $green;
        border-bottom: 3px solid $green;
      }
      &.inactive {
        color: $grayDisabled;
        border-bottom: 3px solid $grayDisabled;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login-bg {
    width: 100%;
    position: fixed;
  }

  .right-half {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: auto;

    .container {
      padding: 1rem;
      height: auto;
      transform: none;
      background: rgba(255, 255, 255, 0.95);
      left: 0%;
      min-height: 90vh;
      max-width: 720px;

      .texto-simples-grande {
        font-size: 20px;
      }
    }
    background: rgba(255, 255, 255, 0.95);
  }

  .logo-sigpae {
    img {
      max-width: 100%;
    }
  }

  div.input-group.email-sme .input-group-append {
    padding-top: 0;
  }
}

.primeiro-acesso {
  p {
    text-align: center;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .right-half {
    .container {
      padding: 1rem 5rem;
    }
  }
}
