@import "../../../styles/variables.scss";

.tabela-faixa-etaria {
  border-radius: 4px;
  border: 1px solid #e9eaea;
  margin: 2em 0 2em 0;

  .tabela-rows {
    display: grid;
    grid-template-columns: 7fr 2fr 2fr;
    border-bottom: 1px solid #e9eaea;
    min-height: 48px;
    height: auto;
    font-weight: 500;
    line-height: 18px;
    color: #686868;

    div {
      font-style: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div:first-child {
      justify-content: flex-start;
      margin-left: 15px;
    }

    .faixa-etaria {
      border-right: 0.5px solid #e9eaea;
    }
    .alunos-matriculados {
      text-align: center;
      border-left: 0.5px solid #e9eaea;
      border-right: 0.5px solid #e9eaea;
    }
    .quantidade {
      border-left: 0.5px solid #e9eaea;
    }
  }

  article:first-child {
    background-color: #f5f6f8;
    font-weight: bold;
    line-height: 16px;
    color: #42474a;
  }

  article:last-child {
    background-color: #f5f6f8;
    font-weight: bold;
    line-height: 16px;
    color: #42474a;
    border-bottom: 0px;
  }
}
