.tem-solicitacao-cadastro-produto {
  color: #198459 !important;
}

.sectionName {
  color: #198459;
  font-weight: bold;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
}

.observacoes {
  background: #dadada;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  padding: 1.5%;
  min-height: 38px;
  p {
    color: #42474a;
    font-weight: 900;
  }
}

.foto-aluno {
  img {
    height: 88px;
    border-radius: 4px;
  }
}

.foto-legenda {
  width: 10em !important;
  font-size: 0.6rem !important;
  margin-top: 0.5em !important;

  img {
    height: 1rem;
  }
}
.custom-col-width {
  width: auto;
}
