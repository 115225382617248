@import "../../../styles/variables.scss";

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-pagination-item {
    width: 32px;
    height: 32px;
    color: #999;
    font-weight: 600;
    background-color: $white;
    border: 1px solid #dadada;
    border-radius: 3px;

    &:hover {
      border: 1px solid $green;
    }
  }

  .ant-pagination-item-active {
    color: $white;
    background-color: $green;
    border: 1px solid $green;
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    border: 1px solid #dadada;

    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      fill: #999;
      stroke: #999;
      stroke-width: 100;
    }
  }

  .anticon {
    vertical-align: 0.15rem;
  }
}
