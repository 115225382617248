.modal-termos-de-uso {
  color: #42474a;

  .modal-header {
    background-color: #f3f3f3;
    margin: 10px;
  }

  .modal-title {
    font-size: 15px;
  }

  label,
  input {
    cursor: pointer;
  }
}
