@import "styles/variables.scss";

.detalhes-collapse-cei {
  background: #dfdfdf;

  .linha {
    display: grid;
    border-bottom: 1px solid #c2c2c2;
    min-height: 48px;
    align-items: center;
  }

  .linha-1 {
    p {
      font-weight: bold;
      padding: 5px 5px 5px 10px;
    }
  }

  .linha-2 {
    padding: 5px 5px 5px 30px;
    grid-template-columns: 73% 27%;
  }

  .item {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
    font-weight: 500 !important;
  }

  .item-periodo {
    p {
      font-weight: 700 !important;
      font-size: 14px;
      padding-left: 20px;
    }
  }
}
