.card {
  &.configuracao-mensagem {
    .col-sm-12,
    .col-xs-12,
    .col-md-12,
    .col-lg-12 {
      padding: 0;
    }
    label.label {
      font-weight: bold;
      font-size: 14px;
      color: #686868;
    }
    input {
      background-color: #e8e8e8;
    }
    .cancel-notification {
      position: relative;
      height: 40px;
      width: 157px;
      background: #eeeeee;
      border: 1px solid #dadada;
      box-sizing: border-box;
      border-radius: 3px;
      label {
        padding-top: 12px;
        padding-left: 30px;
        display: flex;
        justify-content: center;
        font-size: 10px;
        color: #42474a;
      }
      .checkbox-label {
        .checkbox-custom {
          margin-left: 12px;
        }
      }
    }
  }
}
