div.modal-editar-sobremesa {
  div.modal-content {
    margin-top: 25%;
    min-height: 40vh;
  }
  div.footer {
    position: absolute;
    right: 1em;
    bottom: 1em;
  }
}
