@import "styles/variables.scss";

#wrapper {
  .ant-switch-checked {
    background-color: #198459;
  }
  .ant-click-animating-node {
    -webkit-box-shadow: 0 0 0 0 #198459;
    box-shadow: 0 0 0 0 #198459;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #198459;
    border-color: #198459;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #198459;
  }

  .ant-checkbox-wrapper {
    color: #42474a;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

hr {
  color: $grayLight !important;
}
