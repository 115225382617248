.descricao-reclamação {
  margin-bottom: 1em;
  .box-detalhe-reclamacao {
    border: 1px solid black;
    border-radius: 4px;
    min-height: 60px;
    display: block !important;
    align-items: center;
    padding: 0 1em;
  }
}
