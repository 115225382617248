@import "styles/variables.scss";

.cadastro-de-clausulas {
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
    margin-top: 0.5rem;
  }

  div.textarea {
    width: 100%;

    div.error-or-warning-message .error-message {
      color: #ff4d4f;
    }
    .invalid-field {
      border-color: #ff4d4f !important;
    }
  }

  .ant-form-item {
    flex: 1;
    width: 100%;

    & > div {
      display: flex;
      flex-direction: column;

      .ant-form-item-label {
        display: flex;

        label {
          justify-self: flex-start;
        }
      }

      .ant-input-number-affix-wrapper.input-make-field,
      .ant-input {
        height: 37px;
        width: 100%;
        line-height: 37px;
        margin-top: 0.4em;
      }

      .ant-input-number-input-wrap {
        line-height: 37px;
      }
    }
  }
}
