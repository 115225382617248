@import "../../../../../../styles/variables.scss";

.card-cadastro-embalagem {
  min-height: 550px;
  .cadastro-embalagem {
    .card-title {
      &.green {
        color: $green !important;
      }
      font-weight: bold;
      margin-top: 1.5rem;
    }
  }
}
