@import "styles/variables.scss";

.rascunhos-inclusao {
  display: flex;
  justify-content: space-between;

  padding: 1em;
}

.titulo-solicitacao {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #42474a;
}

.formulario-inclusao-alimentacao-cei {
  min-height: 70vh;
  .motivo-data {
    display: grid;
    grid-template-columns: 68% 20%;
    column-gap: 2%;

    .input-data {
      width: 100%;
    }
  }

  .botoes-submit-inclusoes-cei {
    display: flex;
    justify-content: flex-end;
  }

  .periodo-e-tipo-de-alimentacoes {
    font-size: 14px;
    color: $gray;

    .periodos-da-cei {
      display: flex;
      width: 100%;

      .input-radio-periodo {
        margin-right: 5px;
        background-color: #ebedff;
        border: 1px solid #b2baff;
        box-sizing: border-box;
        border-radius: 4px;
        height: 39px;
        flex-grow: 1;

        .container-radio-input {
          display: block;
          position: relative;
          padding-left: 25px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 14px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          nav {
            margin: 0.5em;
            color: #42474a;
            font-weight: 500;
            font-size: 14px;
          }
        }

        .container-radio-input input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }

        .checkmark {
          margin: 0.2em 0 0 0.7em;
          position: absolute;
          top: 0;
          left: 0;
          height: 15px;
          width: 15px;
          background-color: #ffffff;
          border-radius: 50%;
          visibility: visible;
          border: 1px solid #b2baff;
        }

        .container-radio-input:hover input ~ .checkmark {
          background-color: #ffffff;
        }

        .container-radio-input input:checked ~ .checkmark {
          background-color: #0c6b45;
        }

        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        .container-radio-input input:checked ~ .checkmark:after {
          display: block;
        }

        .container-radio-input .checkmark:after {
          top: 4px;
          left: 4px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }
}

.alimentosStyle {
  font-weight: 700;
  color: #198459;
}
