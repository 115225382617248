@import "styles/variables.scss";

div.adicionar-parametrizacao {
  .titulo-tag {
    padding: 0.4rem;
    color: $blackInput;
  }

  h2.texto-simples-verde {
    line-height: 28px;
  }

  .periodo-integral,
  .turma-emebs-fundamental {
    background-color: #ffd869;
  }

  .periodo-parcial,
  .turma-emebs-infantil {
    background-color: #b7e487;
  }

  .turma-emei {
    background-color: #9dc7ff;
  }

  div.container-tabelas {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 0 2%;
  }

  table {
    .ant-form-item {
      margin-bottom: 0;
    }

    th {
      background-color: $green !important;
      color: $white !important;
    }

    thead tr th:first-child {
      background-color: $greenDark !important;
    }
  }
}
