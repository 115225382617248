@import "styles/variables.scss";

.card-atribuir-questoes-conferencia {
  .lable-destacado-verde {
    color: $green;
    font-weight: bold;
  }

  .ant-input-disabled {
    color: $blackInput;
  }
}
