@import "../../../../styles/variables.scss";

.card-conferencia-guia-reposicao {
  .conferencia-guia-reposicao {
    .subtitulo {
      font-weight: 500;
      font-size: 20px;
      color: #42474a;
    }
    .header-alimento {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: space-between;
      background: $greenDark;
      border-radius: 4px;
      color: white;
      padding: 6px;
      font-size: 18px;
      line-height: 21px;
    }
    .info-field {
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
    }

    .texto-intro {
      color: #686868;
    }

    .table-reposicao {
      height: 73%;
      th {
        background-color: $greenLight;
        color: $white;
        padding: 6px 4px 6px 8px;
        font-size: 12px;
        letter-spacing: 0.02rem;

        .text-center {
          padding: 6px 0px;
        }
      }

      td {
        width: 16.666%;
        font-size: 14px;
      }

      .form-tabela {
        width: 100%;
      }

      .th-recebido {
        background-color: #198459;
      }
    }

    .tooltip-botao {
      position: relative;
      display: inline-block;
    }

    .tooltip-botao .tooltiptext {
      visibility: hidden;
      width: 150%;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;

      border-radius: 4px;
      padding: 6px 8px;
      position: absolute;
      z-index: 1000;
      bottom: 125%;
      left: -25%;
      opacity: 0;
      transition: opacity 0.1s;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.5;
      list-style: none;
      text-decoration: none;
      word-wrap: break-word;
      -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    .tooltip-botao .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
    }

    .tooltip-botao:hover button:disabled + .tooltiptext {
      visibility: visible;
      opacity: 1;
    }

    .aviso-alimentos {
      color: #42474a;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      letter-spacing: 0.01em;

      .red {
        color: #b40c02;
      }

      .green {
        color: #198459;
      }
    }

    .titulo-tabela {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      padding-bottom: 6px;
      color: #42474a;
    }

    .span-icone-toogle {
      padding: 8px 12px;
      font-size: 16px;
      color: #42474a;
    }

    #accordionAlimentos > .card {
      border-radius: 5px;
    }

    #accordionAlimentos > .card > .card-header {
      border-radius: 5px;
      margin-bottom: 0;
      background-color: #fff;
    }

    #accordionAlimentos .card-header,
    #accordionAlimentos .card-body {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .card-header {
      height: 50px;

      .card-header-content {
        height: 100%;
        align-content: center;
        justify-content: space-between;
        color: #42474a;

        .nome-alimento {
          margin: auto 0;
        }
      }
    }

    .card-tipo-red {
      border-left: #b40c02 solid 8px;
    }

    .card-tipo-green {
      border-left: #198459 solid 8px;
    }
  }
}
