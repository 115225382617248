.row-contador {
  background: #f1f1f1;
  border: 1px solid #dadada;
  border-radius: 3px;
}

.total-suspensos {
  font-weight: 400;
}

.titulo-quantitativo-supenso {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #198459;
}
