.card-gestao-acesso {
  margin-bottom: 15em !important;
  color: #42474a;

  .gestao-acesso {
    .menu-inferior {
      font-size: 14px !important;
    }
  }
}
