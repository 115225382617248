@import "styles/variables.scss";

.relatorio-solicitacoes-alimentacao {
  min-height: 70vh;

  label {
    font-size: 14px;
    padding-bottom: 0.5em;
    color: $gray;
  }

  .label-periodo-cei-cemei {
    background: #f1f1f1;
    border: 1px solid #dadada;
    border-radius: 5px;
    margin: 1% 0px;
    width: 100%;
    padding: 8px 15px;
    height: 40px;
  }

  .observacao-negrito p {
    font-weight: bold;
  }

  .dia-cancelado {
    text-decoration: line-through;
  }

  .cancelado_justificativa {
    word-break: break-word;
  }

  .linha-divisoria {
    height: "1px";
    background-color: "#858796";
  }

  .quantitativo {
    font-size: 16px;
    font-weight: 600;
  }

  .totalizador {
    padding: 0.5em;
    color: $green;
    font-weight: 500;
    border: 1px solid $green;
    border-left: 5px solid $green;
    border-radius: 4px;

    .valor {
      font-weight: bolder;
    }
  }
}
