.cabecalho-painel {
  span {
    margin-left: 1em;
  }
  i.fas.fa-times-circle {
    color: red;
  }
  i.fas.fa-check-circle {
    color: green;
  }
  .col-4 {
    align-items: center;
    display: flex;
  }
}

.info-unid-escolar {
  border-top: 1px solid #dadada;
}

.foto-aluno {
  img {
    height: 88px;
    border-radius: 4px;
  }
}

.input-info-aluno {
  color: #6e707e !important;
  background-color: #eaecf4 !important;
}
