.modal-cestas {
  max-width: 60vw !important;
  color: #42474a;

  .fas {
    font-size: 80px;
  }

  .gray-bg,
  .modal-header {
    background-color: #f3f3f3;
  }
}
