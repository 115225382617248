.modal-ativacao-produto {
  .row-ativacao {
    margin-bottom: 4em;
  }
}

.input-nome > div > input {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.multiselect-editais {
  z-index: 10;
}
