@import "styles/variables.scss";

.tabela-downloads {
  margin-top: 10px;

  header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #42474a;
  }

  article {
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e9eaea;
    box-sizing: border-box;
    margin: 10px 0 15px 0;

    .grid-table {
      min-height: 48px;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      display: grid;
      grid-template-columns: 0.9fr 0.4fr 0.5fr 0.2fr 0.3fr;
    }

    .header-table {
      background: #f5f6f8;
      color: #42474a;
      font-weight: bold;
      div {
        text-align: left;
        display: grid;
        align-items: center;
        height: 100%;
        padding-left: 8px;
        &:not(:last-child) {
          border-right: 1px solid #e9eaea;
        }

        &:last-child {
          border-right: 0px solid #e9eaea;
        }
      }
    }

    .body-table {
      font-weight: 500;
      color: #686868;

      .fas {
        font-weight: 900;
        font-size: 16px;
        line-height: 18px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
      }

      .vermelho {
        color: $red;
      }

      .verde {
        color: $green;
      }

      .icon-link {
        cursor: pointer;
      }

      button {
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
      }

      button:disabled {
        color: #bcbcbc;
        cursor: no-drop;
      }

      .flex-container {
        display: flex;
        justify-content: space-evenly;
        padding-left: 0;
      }

      .center {
        justify-content: center;
      }

      div {
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e9eaea;
        text-align: left;
        padding-left: 8px;
        &:not(:last-child) {
          border-right: 1px solid #e9eaea;
        }
      }

      div:last-child {
        border-right: 0px solid #e9eaea;
      }
    }
  }
}
