.data_inicio {
  label {
    font-weight: 500 !important;
    color: #686868 !important;
  }
}

.filtros-requisicoes-listagem {
  padding-bottom: 40px;

  .form-control {
    height: 38px !important;
  }
}
