@import "../../../styles/variables.scss";

button.general-button {
  border-radius: 3px;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  height: 38px;
  padding: 0 12px;
  text-align: center;
  border: 1px solid;
  i.icone-info-invalid {
    background-color: $blackSubtle;
    height: 16px;
    width: 16px;
    line-height: 16px;
    border-radius: 50%;
    font-size: 9px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    margin-left: 10px;
  }
  i.text-and-icon-left {
    padding-right: 8px;
  }
  i.text-and-icon-right {
    padding-left: 8px;
  }
  &.blue-button {
    background-color: $blueButtonFont;
    border: none;
    color: $white;
    i {
      color: $white;
    }
    &:hover {
      background-color: $blueButtonBackground;
    }
    &:disabled {
      background-color: transparent;
      color: $grayDisabled;
      border: 1px solid $grayDisabled;
    }
  }
  &.blue-button-outline {
    color: $blueButtonFont;
    border-color: $blueButtonFont;
    background-color: transparent;
    i {
      color: $blueButtonFont;
    }
    &:hover {
      color: $white;
      background-color: $blueButtonBackground;
      i {
        color: $white;
      }
    }
    &:disabled {
      background-color: transparent;
      color: $grayDisabled;
      border: 1px solid $grayDisabled;
    }
  }
  &.green-button {
    background-color: $green;
    border: 1.5px solid $green;
    color: $white;
    i {
      color: $white;
    }
    &:hover {
      background-color: $greenButtonHover;
    }
    &:disabled {
      background-color: transparent;
      color: $grayDisabled;
      border: 1px solid $grayDisabled;
    }
  }
  &.green-button-outline {
    color: $green;
    border: 1px solid $green;
    background-color: transparent;
    &:not(:disabled) {
      i {
        color: $green;
      }
    }

    &:hover:not(:disabled) {
      color: $white;
      background-color: $greenButtonHover;
      i {
        color: $white;
      }
    }
    &:disabled {
      background-color: transparent;
      color: $grayDisabled;
      border: 1px solid $grayDisabled;
    }
  }
  &.green-button-outline-white {
    color: $green;
    border: 1px solid $green;
    background-color: white;
    &:not(:disabled) {
      i {
        color: $green;
      }
    }

    &:hover:not(:disabled) {
      color: $white;
      background-color: $greenButtonHover;
      i {
        color: $white;
      }
    }
    &:disabled {
      background-color: white;
      color: $grayDisabled;
      border: 1px solid $grayDisabled;
    }
  }
  &.red-button {
    color: $white;
    border: 1.5px solid $redCard;
    background-color: $redCard;
    i {
      color: $white;
    }
    &:hover {
      color: $redCard;
      background-color: $white;
      i {
        color: $redCard;
      }
    }
    &:disabled {
      background-color: transparent;
      color: $grayDisabled;
      border: 1px solid $grayDisabled;
    }
  }
  &.red-button-outline {
    color: $redCard;
    border: 1px solid $redCard;
    background-color: transparent;
    i {
      color: $redCard;
    }
    &:hover {
      color: $white;
      background-color: $redCard;
      i {
        color: $white;
      }
    }
    &:disabled {
      background-color: transparent;
      color: $grayDisabled;
      border: 1px solid $grayDisabled;
    }
  }

  &.dark-button-outline {
    color: $blackInput;
    border-color: $blackInput;
    background-color: transparent;

    &:not(:disabled) {
      i {
        color: $blackInput;
      }
    }
    &:hover:not(:disabled) {
      color: $white;
      background-color: $blackInput;
      i {
        color: $white;
      }
    }
    &:disabled {
      background-color: transparent;
      color: $grayDisabled;
      border: 1px solid $grayDisabled;
    }
  }

  &.orange-button-outline {
    color: $orange;
    border: 1px solid $orange;
    background-color: transparent;
    &:not(:disabled) {
      i {
        color: $orange;
      }
    }

    &:hover:not(:disabled) {
      color: $white;
      background-color: $orange;
      i {
        color: $white;
      }
    }
    &:disabled {
      background-color: transparent;
      color: $grayDisabled;
      border: 1px solid $grayDisabled;
    }
  }

  &.no-border {
    border: none;
  }
}
