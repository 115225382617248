@import "../../../styles/variables.scss";

.card-shortcut-to-form {
  font-size: 14px;
  .card-body {
    .card-title {
      color: $blackInput;
    }
    p {
      color: $gray;
    }
  }
}
