.table-alimentacao {
  font-family: Roboto;
  font-style: normal;

  .table-head-alimentacao {
    font-weight: bold;
    font-size: 14px;
    background: #e5e5e5;
    color: #42474a;
  }
  .table-body-alimentacao {
    font-size: 13px;
    color: #42474a;
  }
}

.data-title {
  color: #198459;
  font-weight: bold;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
}

p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #42474a;
}
