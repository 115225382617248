@import "styles/variables.scss";

.guias-inconsistencias {
  margin-top: 10px;

  header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #42474a;
  }

  article {
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e9eaea;
    box-sizing: border-box;
    margin: 10px 0 15px 0;

    .grid-table {
      min-height: 48px;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      display: grid;
      grid-template-columns: 0.6fr 0.6fr 1.8fr;
    }

    .header-table {
      background: #f5f6f8;
      color: #42474a;
      font-weight: bold;
      div {
        text-align: left;
        display: grid;
        align-items: center;
        height: 100%;
        padding-left: 8px;
        &:not(:last-child) {
          border-right: 1px solid #e9eaea;
        }

        &:last-child {
          border-right: 0px solid #e9eaea;
        }
      }
    }

    .body-table {
      font-weight: 500;
      color: #686868;

      div {
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e9eaea;
        text-align: left;
        padding-left: 8px;
        &:not(:last-child) {
          border-right: 1px solid #e9eaea;
        }
      }

      div:last-child {
        border-right: 0px solid #e9eaea;
      }
    }

    .acoes {
      font-size: 14px !important;
      display: inline-block;
    }
  }
}

.button-container {
  margin: 1.5rem;
  padding: 0;
}
