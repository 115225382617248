.table-especificacoes {
  font-family: Roboto;
  font-style: normal;

  .table-head-especificacoes {
    font-weight: bold;
    font-size: 14px;
    background: #e5e5e5;
    color: #42474a;
  }

  .table-body-especificacoes {
    font-size: 13px;
    color: #42474a;
  }
}
