.modal-guia-remessa-info {
  width: 70vw !important;
  max-width: 70vw !important;

  b {
    color: #198459;
  }

  article {
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e9eaea;
    box-sizing: border-box;
    margin: 10px 0 15px 0;

    .grid-table {
      height: 36px;
      font-size: 14px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      display: grid;
      grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    }

    .header-table {
      background: #198459;
      color: #ffffff;
      font-weight: bold;
      div {
        text-align: left;
        display: grid;
        align-items: center;
        height: 100%;
        padding-left: 8px;
        &:not(:last-child) {
          border-right: 1px solid #e9eaea;
        }

        &:last-child {
          border-right: 0px solid #e9eaea;
        }
      }
    }

    .body-table {
      font-weight: 500;
      color: #686868;

      .desativar-borda {
        border-left: 0px solid #e9eaea;
        color: #42474a;
        font-weight: 900;
      }

      div {
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e9eaea;
        text-align: left;
        padding-left: 8px;
        &:not(:last-child) {
          border-right: 1px solid #e9eaea;
        }
      }

      div:last-child {
        border-right: 0px solid #e9eaea;
      }
    }
  }
}
.button-row {
  justify-content: right;
}

.tooltip-botao {
  position: relative;
  display: inline-block;
}
.tooltip-botao .tooltiptext {
  visibility: hidden;
  width: 200%;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;

  border-radius: 4px;
  padding: 6px 8px;
  position: absolute;
  z-index: 1000;
  bottom: 125%;
  left: -50%;
  opacity: 0;
  transition: opacity 0.1s;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  list-style: none;
  text-decoration: none;
  word-wrap: break-word;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.tooltip-botao .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}

.tooltip-botao:hover button:disabled + .tooltiptext {
  visibility: visible;
  opacity: 1;
}
