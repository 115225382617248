@import "styles/variables.scss";

.componente-motivo-da-suspensao {
  color: #42474a;

  .titulo-suspensao {
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
  }

  .texto-wysiwyg-suspensao {
    border: 1px #dddddd solid !important;
    padding: 0.5rem;
    font-size: 12px;
    border-radius: 2px;
  }
}
