@import "styles/variables.scss";

.link-botao-anexo {
  &:hover {
    text-decoration: none;
  }
}

.botao-anexo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $greenDark;
  border: 2px dotted $greenDark;
  border-radius: 5px;
  background-color: transparent;
  padding: 10px 0;
  font-weight: bold;
  cursor: pointer;
}
