@import "../../../../../styles/variables.scss";

div.cadastro-produto-step3 {
  .card-title {
    padding-top: 1.5em;
    font-weight: bold;
    font-size: 14px;
    color: $blackInput;
  }
  textarea::placeholder {
    text-align: left;
  }
  div.produto {
    font-size: 14px;
    label {
      color: $blackInput;
      span {
        color: $red;
      }
    }
    div.explicacao {
      color: $gray;
    }
  }
}

.attachments {
  .image-required {
    color: #a50e05;
    padding-right: 5px;
  }
  .image-label-cadastro-produto {
    font-size: 14px !important;
    color: #686868 !important;
    font-weight: normal !important;
  }
  .text {
    font-size: 14px;
    color: #686868;
  }
  .card-warning {
    border: 1px solid $red;
    color: $red;
    font-size: 10px;
    padding: 10px;
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0% !important;
  }

  .btn-produto {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0% !important;
  }
}

.section-cards-imagens {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-top: 0px !important;

  .link {
    color: #086397 !important;
  }

  .arquivos-anexados {
    background: #f3f3f3;
    max-width: 48%;
  }
}

.delete {
  margin-left: 2em;
  font-weight: bold;
  background-color: $blue;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: $blue;
  }
}
