@import "styles/variables.scss";

.periodo-info-ue,
.info-med-inicial {
  .periodo-lancamento,
  .info-ue,
  .panel-med-inicial {
    div {
      width: 100%;
    }
  }

  .ant-select-selection__rendered,
  .ant-select-selection-selected-value {
    height: 38px !important;
    line-height: 38px !important;
  }

  .ant-skeleton-title {
    margin-top: -4px !important;
    height: 40px !important;
  }
  .asterisk-label {
    color: $red;
    margin-left: 2px;
  }
}

.collapse-adjustments {
  .ant-collapse-header {
    display: block !important;
    border-left: 6px solid $green;
    border-radius: 2px 0 0 2px !important;
  }

  .ant-collapse-item {
    svg {
      transform: rotate(90deg);
    }
  }

  .ant-collapse-item-active {
    svg {
      transform: rotate(-90deg) !important;
    }
  }
}

.label-adjustments {
  .ant-collapse-header {
    display: flex !important;
    flex-direction: row !important;

    .ant-collapse-header-text {
      width: 100% !important;
    }

    .ant-collapse-expand-icon {
      width: 1%;
      margin-right: 20px !important;
    }
  }

  .info-label {
    font-size: 14px;
    color: $gray;

    .value-label {
      font-weight: bold;
      font-size: 15px;
      color: $blackInput;
    }
  }

  .select-medicao-inicial {
    .ant-select {
      svg {
        transform: rotate(0deg) !important;
      }
    }
    .ant-select-open {
      svg {
        transform: rotate(180deg) !important;
      }
    }
  }
}

.section-title {
  color: $green;
  font-size: 16px;
}

.ultimo-salvamento {
  font-size: 12px;
  color: $gray;
}

.radio-opt-positive {
  border: 1px solid $green;
  border-radius: 4px;
  margin-left: 20px;
  margin-right: 24px;
  padding: 8px 58px 8px 12px;
}

.radio-opt-negative {
  border: 1px solid $red;
  border-radius: 4px;
  padding: 8px 58px 8px 12px;
}

.ant-skeleton-title {
  margin-top: 24px !important;
  height: 40px !important;
}

.custom-style-help-text {
  margin-top: 2%;
  padding: 1%;
  border-radius: 5px;
  border: solid 1px #a4a4a4;
}

div.bloco-ocorrencias {
  font-size: 14px;

  div.box {
    padding: 1em !important;
    border: 1px solid $grayDisabled;
    border-radius: 5px;

    input[type="radio"] {
      accent-color: $green;
    }

    .mensagem-erro {
      font-size: 11px;
      color: $red;
    }
  }
}
