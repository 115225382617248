@import "styles/variables.scss";

div.acompanhamento-de-lancamentos {
  font-size: 12px;
  color: $blackInput;

  label {
    font-size: 14px;
    color: $gray;
  }

  div.card-body {
    padding-bottom: 50px !important;

    div.ue-botoes {
      padding-bottom: 80px !important;
    }
  }

  div.card-medicao-por-status {
    height: 130px;
    width: 148px;
    border: 1px solid black;
    border-left: 7px solid black;
    border-radius: 4px;
    cursor: pointer;

    hr {
      margin: 0;
    }

    div.titulo {
      font-weight: 700;
      font-size: 13px;
      min-height: 3em;
    }

    div.total {
      font-weight: 700;
      font-size: 48px;
    }

    div.conferir-lista {
      font-weight: 400;
      font-size: 10px;
      margin-top: -1.5em;
      color: $blackInput;
    }

    &.azul-claro {
      color: $blueMI;
      border-color: $blueMI;
    }

    &.laranja {
      color: $orange;
      border-color: $orange;
    }

    &.azul-escuro {
      color: $blue;
      border-color: $blue;
    }

    &.verde-claro {
      color: $green;
      border-color: $green;
    }

    &.verde-escuro {
      color: $greenDark;
      border-color: $greenDark;
    }

    &.vermelho {
      color: $redMI;
      border-color: $redMI;
    }

    &.cinza {
      color: $grayPlaceholder;
      border-color: $grayPlaceholder;
      cursor: default;

      &.cursor-pointer {
        cursor: pointer;
      }
    }

    &.greenLight {
      color: $greenLight;
      border-color: $greenLight;
    }
  }

  div.titulo-tabela {
    font-weight: 600;
    font-size: 14px;
    color: $greenDark;
  }

  table.resultados {
    width: 98%;
    font-size: 14px;
    color: $blackInput;
    margin-left: 1em;

    thead {
      tr {
        background-color: $grayLightHeader;
        font-weight: 700;

        th {
          border: 1px solid $grayDisabled;
          padding: 0.5em;
        }
      }
    }

    tbody {
      tr {
        td {
          border: 1px solid $grayDisabled;
          padding: 0.5em;
        }
      }
    }
  }
  .seletor-dre {
    margin-top: 1em;
    padding-left: 0.5em;
    .ant-select-selector {
      border-radius: 8px;
      font-weight: bolder;
      font-size: 16px;
      color: $gray;
      &:hover {
        border-color: $green;
      }
    }
  }

  .botoes-relatorios {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
  }
}
