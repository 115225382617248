@import "styles/variables.scss";

.modal-downloads {
  width: 40vw !important;
  max-width: 40vw !important;

  margin-top: 35vh !important;

  .modal-title {
    font-size: 18px;
    font-weight: 600;
    color: $blackInput;
  }

  .modal-body {
    font-size: 15px;
    padding: 2rem 1rem;

    p {
      padding: 0;
      margin: 0;
    }
  }
}
